import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

if (!i18n.isInitialized) {
  i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      resources: {
        en: {
          translation: {
            menu: {
              home: 'Home',
              aboutUs: 'About Us',
              missionVision: 'Mission & Vision',
              coreValues: 'Core Values',
              test: 'Test',
              services: 'Services',
              contactUs: 'Contact Us',
              socials: 'Socials',
              login: 'Login',
              logout: 'Logout',
            },
            header: {
              // main: "Your <span class='highlight'>Career</span>, Your <span class='highlight'>Dream</span>",
              mainOne: 'Your Career, Your Dreams ',
              mainTwo: 'Our Guidance Expertise',
              sub: 'Discover the perfect career path for you and turn your aspirations into achievable goals. Rely on our expert insights and tailored advice.',
            },
            missionVision: {
              mission: {
                title: 'Mission',
                text: `Support individuals on their path to fulfilling and lasting careers by offering professional advice, promoting skill growth, and building a vibrant support community. We dedicate ourselves to providing the resources and insights necessary to successfully navigate today's complex job landscape, ensuring individuals excel in their chosen professions and make significant contributions to society.`,
              },
              vision: {
                title: 'Vision',
                text: `We envision a society where everyone has the skills, confidence, and resilience to craft a purposeful career path, contributing to a thriving and dynamic global workforce. We aim to build a new future with a skilled and professional generation, equipped to meet the challenges and opportunities of the ever-evolving job market, by fostering continuous learning and adaptability.`,
              },
            },
            coreValues: {
              title: 'Core Values',
              integrity: {
                title: 'Integrity',
                description:
                  'Honesty and transparency with our clients, partners, and colleagues. High ethical standards in providing career guidance services.',
              },
              excellence: {
                title: 'Excellence',
                description:
                  'Continuous improvement in career guidance methodologies and practices. Exceeds the expectations in delivering high-quality services.',
              },
              accountability: {
                title: 'Accountability',
                description:
                  'Confidence in the outcomes of our career guidance. Learning from mistakes and actively seeking ways to improve.',
              },
              innovation: {
                title: 'Innovation',
                description:
                  'Stay updated with evolving career landscapes. Encouraging a culture that values new ideas, technologies, and solutions.',
              },
              reliable: {
                title: 'Reliable',
                description:
                  'Consistent and accurate in delivering information and fulfilling promises. Ensuring the credibility and correctness of information delivered.',
              },
            },
            test: {
              mainHeader: 'Choose your Destination',
              subHeader:
                'Click below to start your career test now! Discover your ideal path with personalized insights and expert guidance.',
              startNow: 'Start Now',
            },
            footer: {
              Menu: 'Menu',
              Home: 'Home',
              'Mission & Vision': 'Mission & Vision',
              'Core Values': 'Core Values',
              Test: 'Test',
              'Start✓Right': 'Start✓Right',
              'Contact Us': 'Contact Us',
              Socials: 'Socials',
              'Footer Text':
                'Empowering you to discover your perfect career path and achieve your dreams with expert insights and personalized advice.',
              Copyright: '© {{year}} Start✓Right',
              Developed: 'Designed and Developed by',
            },
            catalogue: {
              title:
                'Get the List of 2024 <span class="highlight">Majors</span>',
              description:
                'Click the button below to download the comprehensive list of majors for 2024 and start planning your future today!',
              downloadTitle: 'Download 2024 Majors List',
            },
            services: {
              title: 'Connect, follow, and stay updated!',
              description: `<p>Follow us on social media or contact our team for <span class='highlight main'> private consultation </span> or further questions.</p>`,
            },
            contactUs: {
              text: 'Need expert advice or have any questions?',
            },
            testPage: {
              header: {
                text: 'Find Your True Calling: A Journey Through Personality, Skills, and Career Paths',
                subText:
                  'Take the first step towards a successful career today! Unlock Your Future and Find the Career That Fits You Best.',
              },
              purchasebtn: {
                text: 'Get Access',
              },
              personalityCards: {
                title: 'Personality Test',
                subHeader: 'Uncover key traits and insights',
                description:
                  'A personality test is an assessment that helps you understand your unique personality traits, behaviors, and preferences. Understanding your personality type is important for both academic and professional development because it can guide you in choosing the right career path, improve your working relationships, and enhance your communication and problem-solving skills. By gaining insight into your strengths and areas for growth, a personality test can help you make informed decisions about your future.',
              },
              skillsCards: {
                title: 'Skills Test',
                subHeader: 'Assess strengths and target areas for growth.',
                description:
                  'A skills test is an assessment that evaluates your proficiency in key areas relevant to your academic and professional goals. By identifying your strengths and areas for improvement, this test provides valuable insights that can help you enhance your skills, focus your learning efforts, and better prepare for your career. The results, presented as a percentage, offer a clear understanding of your current skill level, helping you to set targeted goals for personal and professional development.',
              },
              careerCards: {
                title: 'Career Test ',
                subHeader: 'Unlock your ideal career path',
                description:
                  'A career test is an assessment designed to help you identify majors that align with your interests, strengths, and personality. By taking a career test, you gain valuable insights into which fields or roles are best suited to your unique qualities, increasing your chances of finding a fulfilling and successful career path. Understanding which careers match your natural abilities and passions enables you to make more informed decisions about your academic and professional journey, leading to greater satisfaction and achievement in both your studies and your career.',
              },
            },
            buttons: {
              back: 'Back',
              more: 'View More',
              start: 'Start Now',
              next: 'Next',
              submit: 'Submit',
            },
            personalityPage: {
              title: 'Personality Test',
              subtitle:
                'Unlock the secrets of your unique personality! Dive into this exciting journey of self-discovery and uncover the traits that define who you are. Get ready to explore the depths of your character and unleash your true potential!',
              content:
                'Take your time answering each question—this could shape your future. Be patient and thoughtful as you discover your path to success.',
            },
            skillsPage: {
              title: 'Skills Test',
              subtitle: `Unleash your potential with our thrilling Skills Test! Dive in to discover your hidden talents and sharpen the abilities that set you apart. Whether you're a problem-solving genius, a creative thinker, or a master of communication, this test will highlight your strengths and show you what you’re truly capable of. Get ready to uncover your superpowers!`,
              content: `Take your time answering each question—this could shape your future. Be patient and thoughtful as you discover your path to success.`,
            },
            careerPage: {
              title: 'Career Test ',
              subtitle:
                ' Embark on an exciting journey to find your perfect career! Our Career Test will guide you through a series of questions designed to match you with over 140+ majors. Discover the fields that align with your passions and strengths, and unlock a world of possibilities for your future. Get ready to find your dream career!',
              content:
                'Take your time answering each question—this could shape your future. Be patient and thoughtful as you discover your path to success.',
              industry: {
                header: 'Discover Your Career Preferences',
                description:
                  'In this first step of our career test, you will provide insights into your preferences and interests. You willl answer questions about your preferred industry, the type of workplace you envision, the kind of work you enjoy, and the courses you are eager to explore. Your responses will help us understand your career aspirations and tailor recommendations that align with your interests.',
              },
              subject: {
                header: 'Assess Your Academic Performance',
                description:
                  'In this step, we will review your academic performance and grades in various subjects. This information will help us understand your academic strengths and identify the areas where you excel, assisting you in choosing the right academic paths.',
              },
              simulation: {
                header: 'Future Major Simulation Test',
                description:
                  'In this final stage, you will take a comprehensive simulation test related to academic majors. You will be asked a series of detailed questions about various majors, which will help us assess how well your answers align with the majors you initially selected.<br /><br />But we won’t just tell you if you are a good fit for the majors you chose. We will also provide suggestions for other majors that might be a better fit for you based on your answers in this test, even if the majors you initially selected are not the best match. The goal is to help you explore new options that might better align with your interests and abilities.',
              },
            },
            loginPage: {
              signup: 'Sign Up',
              signuptext: 'Fill out the form to create an account.',
              login: 'Log In',
              logintext: 'Enter your credentials to access your account.',
              or: 'OR',
              email: 'Email',
              password: 'Password',
              confirmPassword: 'Confirm Password',
              username: 'Username',
              schoolname: 'School Name',
              phonenumber: 'Phone Number',
              dateofbirth: 'Date of Birth',
              backtologin: 'Back to Login',
            },
            popUps: {
              errorMsg: 'Something went wrong, please try again later.',
              downloadpdf: 'Download PDF',
              purchasePopUp: {
                // content:
                //   "The process is quick and straightforward. You will be redirected to WhatsApp with a pre-written message to request access to the test.",
                header: 'Access Unavailable',
                content:
                  'You’ll be taken to WhatsApp with a pre-filled message to request access for the three tests only for 15$. Just send it to get started!',
                text: 'Get Access',
              },
              incompletePopup: {
                content: 'Please answer all the questions to view the results.',
                button: 'Continue',
              },
              completeTestPopup: {
                content:
                  'You have completed the test. Do you want to see the results?',
                button: 'View Results',
              },
              showResultPopup: {
                title: 'Congratulations on Completing the Test!',
                description:
                  'Based on your responses, we divided the eligible majors into two groups.',
                content: {
                  subHeader:
                    'This group consists of majors derived from your responses in the initial section of the career assessment, which includes insights on industries, workplaces, job types, preferred courses, and your academic performance. Additionally, it incorporates your answers from the simulation test to provide a more comprehensive match:',
                  noResultsMessage:
                    " It looks like there aren't any top majors in your preferred industries right now. But don’t worry!",
                  alternativeMajors: {
                    subHeader:
                      'This group is based solely on your responses to the simulation questions. This means these majors do not align with the answers you provided in the first section of the career assessment (such as industries, workplaces, work type, preferred courses, and grades):',
                    message: ` While your preferred industries didn’t yield top results this time, the test has highlighted other promising majors that align with your skills and interests.`,
                  },
                  additionalMajors: {
                    message:
                      ' No additional majors found, but there are many exciting fields to explore!',
                  },
                  encouragementMessage:
                    ' Remember, this test is just a starting point. Explore other fields where your talents shine and discover new opportunities!',
                },
                button: 'Share with Us',
                skillstitle:
                  '<strong>Congratulations on Completing the Skills Test!</strong>',
                skillsDisplaySentence:
                  'Based on your responses, we have compiled the results of your skills assessment. This report will provide insights into your strengths and areas for improvement. You will also find the skills that best describe you and how they align with potential career paths.',
                personalitytitle:
                  '<strong>Congratulations on Completing the Personality Test!</strong>',
                personalityDisplaySentence:
                  'Based on your responses, we have compiled the results of your personality assessment. This report will provide insights into your personality traits and how they align with potential career paths.',
                noResulttitle:
                  '<strong>Congratulations on Completing the Test!</strong>',
                noResultSkillsContent:
                  'Based on your responses, your skills in creativity, stress management, time management, problem-solving, emotional intelligence, and adaptability are currently below 50%. This indicates a need for growth in these crucial areas, which are essential for your academic and professional success. To enhance these skills, consider enrolling in targeted training programs and making positive changes to your environment to better support your development.',
                noResultPersonalityContent:
                  'Based on your responses, scoring less than 50% indicates that you possess a balanced personality that does not lean heavily towards any specific trait. This means you are highly adaptable and versatile, demonstrating open-mindedness and flexibility. You maintain a balanced perspective, valuing a mix of practicality, creativity, tradition, and social interaction without strongly favoring any one area. This equilibrium allows you to navigate various situations with ease and a well-rounded approach.',
                skillNote:
                  'If any of your skills are currently below 50%, it indicates a need for significant improvement in these essential areas. Enhancing these skills is crucial for both your academic and professional success. To address these gaps, consider engaging in specialized training programs and making positive changes to your environment that support your personal and professional development.',
              },
              continueDialog: {
                title: 'Continue',
                content:
                  'You have previous progress. Do you want to continue where you left off or restart the test?',
                button: 'Continue',
              },
              warningPopUp: {
                title: 'WARNING',
                content:
                  'You will only have one-time access to this quiz. If you wish to retake it, you will need to purchase it again. Please answer all questions patiently and carefully to ensure the best results.',
                agree: 'Agree and Accept',
              },
              ratingPopUp: {
                rating: 'Rate Us',
                feedback: 'Feedback',
                continue: 'Continue',
                title: 'We’d love to hear about your experience!',
                text: 'Please rate your test experience below. Let us know how the questions were and if they were easy. Your feedback helps us improve.',
                titleFeedback: 'Share your feedback here!',
                textFeedback:
                  'How satisfied are you with the results you received? Please provide any comments or suggestions to help us improve.',
                placeholder: 'Share your feedback here',
                veryBad: 'Very Bad',
                bad: 'Bad',
                notGood: 'Not Good',
                belowAverage: 'Below Average',
                average: 'Average',
                fair: 'Fair',
                good: 'Good',
                veryGood: 'Very Good',
                excellent: 'Excellent',
                outstanding: 'Outstanding',
              },
            },
            messages: {
              requestAccess:
                'Hello,\n\nMy name is {{name}}, and my email is {{email}}. I would like to request access to the tests available on your platform. I am eager to continue exploring my career options and believe that these tests will be instrumental in guiding my decisions.\n\nThank you for considering my request.\n\nBest regards,\n{{name}}\nEmail: {{email}}\nPhone Number: {{studentPhoneNumber}}\nDate: {{testDate}}',
              feedback:
                'Hello,\n\nI would like to request assistance and further information.\n\nThank you for considering my request.\n\nBest regards,\nDate: {{testDate}}',
              testResults:
                'Hello,\n\nThis is {{name}} ({{email}}). I have completed the majors test on {{testDate}} and these are the results:\n\nTop Majors in My Preferred Industries:\n{{topSelectedMajors}}\n\nWe have found that I am also a great fit for these other majors:\n{{topNonSelectedMajors}}\n\nBest regards,\n{{name}}\nEmail: {{email}}\nPhone Number: {{studentPhoneNumber}}\nDate: {{testDate}}',
            },
            contactPopup: {
              inquiryTitle: 'Inquiry about Career Path Discovery Tool:',
              placeholderName: 'Your Name',
              placeholderEmail: 'Your Email',
              placeholderPhone: 'Your Phone',
              placeholderMessage: 'Your Request',
              successMessage: 'Your request has been sent successfully!',
              errorMessage:
                'There was an error sending your request. Please try again.',
              sendButton: 'Send',
              closeButton: 'Close',
            },
          },
        },
        ar: {
          translation: {
            menu: {
              home: 'الصفحة الرئيسية',
              aboutUs: 'من نحن',
              missionVision: 'الرسالة والرؤية',
              coreValues: 'القيم الأساسية',
              test: 'الاختبار',
              services: 'الخدمات',
              contactUs: 'تواصل معنا',
              socials: 'وسائل التواصل الاجتماعي',
              login: 'تسجيل الدخول',
              logout: 'تسجيل الخروج',
            },
            header: {
              // main: "مسيرتك المهنية، <span class='highlight'>حلمك</span>",
              mainOne: 'مسيرتك المهنية، أحلامك',
              mainTwo: 'خبرتنا في التوجيه',
              sub: 'اكتشف المسار الوظيفي المثالي لك وحوِّل طموحاتك إلى أهداف قابلة للتحقيق. اعتمد على رؤى خبرائنا والنصائح المخصصة.',
            },
            missionVision: {
              mission: {
                title: 'الرسالة',
                text: `دعم الأفراد في طريقهم نحو حياة مهنية مليئة بالإنجازات من خلال تقديم النصائح المهنية، وتعزيز نمو المهارات، وبناء مجتمع داعم ونابض بالحياة. نكرس أنفسنا لتوفير الموارد والرؤى اللازمة للتنقل بنجاح في المشهد الوظيفي المعقد الحالي، لضمان أن يتفوق الأفراد في مهنهم المختارة ويساهمون بشكل كبير في المجتمع.`,
              },
              vision: {
                title: 'الرؤية',
                text: `نتصور مجتمعًا يتمتع كل فرد فيه بالمهارات والثقة والمرونة لتشكيل مسار وظيفي ذو غرض واضح، مساهمًا في سوق عمل عالمي مزدهر وديناميكي. نهدف إلى بناء مستقبل جديد مع جيل ماهر ومحترف، مجهز لمواجهة تحديات وفرص سوق العمل المتجددة باستمرار من خلال تعزيز التعلم المستمر والقدرة على التكيف.`,
              },
            },
            coreValues: {
              title: 'القيم الأساسية',
              integrity: {
                title: 'النزاهة',
                description:
                  'الصدق والشفافية مع عملائنا وشركائنا وزملائنا. معايير أخلاقية عالية في تقديم خدمات الإرشاد الوظيفي.',
              },
              excellence: {
                title: 'التميز',
                description:
                  'التحسين المستمر في منهجيات وممارسات الإرشاد الوظيفي. تجاوز التوقعات في تقديم خدمات عالية الجودة.',
              },
              accountability: {
                title: 'المساءلة',
                description:
                  'الثقة في نتائج إرشادنا الوظيفي. التعلم من الأخطاء والبحث النشط عن طرق للتحسين.',
              },
              innovation: {
                title: 'الابتكار',
                description:
                  'البقاء على اطلاع بالمناظر الوظيفية المتطورة. تشجيع ثقافة تقدر الأفكار الجديدة والتكنولوجيات والحلول.',
              },
              reliable: {
                title: 'الموثوقية',
                description:
                  'الثبات والدقة في تقديم المعلومات والوفاء بالوعود. ضمان مصداقية وصحة المعلومات المقدمة.',
              },
            },
            test: {
              mainHeader: 'اختر وجهتك',
              subHeader:
                'انقر أدناه لبدء اختبار مسار مهني الآن! اكتشف المسار المثالي لك مع الرؤى الشخصية والإرشاد الخبير.',
              startNow: 'ابدأ الآن',
            },
            footer: {
              Menu: 'القائمة',
              Home: 'الصفحة الرئيسية',
              'Mission & Vision': 'الرؤية والرسالة',
              'Core Values': 'القيم الأساسية',
              Test: 'الاختبار',
              'Start✓Right': 'Start✓Right',
              Services: 'الخدمات',
              'Contact Us': 'تواصل معنا',
              Socials: 'وسائل التواصل الاجتماعي',
              'Footer Text':
                'نمكنك من اكتشاف مسارك المهني المثالي وتحقيق أحلامك بالرؤى الخبيرة والنصائح الشخصية.',
              Copyright: '© {{year}} Start✓Right',
              Developed: 'الموقع من تصميم وتنفيذ',
            },
            catalogue: {
              title:
                'احصل على قائمة التخصصات <span class="highlight">لعام 2024</span>',
              description:
                'انقر على الزر أدناه لتحميل القائمة الشاملة للتخصصات لعام 2024 وابدأ في التخطيط لمستقبلك اليوم!',
              downloadTitle: 'تحميل قائمة تخصصات 2024',
            },
            services: {
              title: 'تواصل وتابع واستمر بالتحديث!',
              description: `<p>تابعنا على وسائل التواصل الاجتماعي أو تواصل مع فريقنا لـ <span class='highlight main'>استشارة خاصة</span> أو لطرح أسئلة إضافية.</p>`,
            },
            contactUs: {
              text: 'تحتاج إلى نصيحة خبيرة أو لديك أي أسئلة؟',
            },
            testPage: {
              header: {
                text: 'اكتشف مسارك الحقيقي: استكشاف الشخصية، المهارات، والمهن',
                subText:
                  'ابدأ الآن لتحقيق النجاح في حياتك المهنية! اكتشف مستقبلك واختر المهنة الأنسب لك.',
              },
              purchasebtn: {
                text: 'اشترك الآن',
              },
              personalityCards: {
                title: 'تقييم الشخصية',
                subHeader: ' اكتشف السمات الرئيسية والرؤى الهامة',
                description:
                  'اختبار الشخصية هو تقييم يساعدك على فهم سمات شخصيتك الفريدة وسلوكياتك وتفضيلاتك. إن فهم نوع شخصيتك أمر مهم لكل من التطور الأكاديمي والمهني لأنه يمكن أن يرشدك في اختيار المسار الوظيفي الصحيح وتحسين علاقاتك العملية وتعزيز مهارات التواصل وحل المشكلات. من خلال اكتساب نظرة ثاقبة لنقاط قوتك ومجالات النمو لديك، يمكن أن يساعدك اختبار الشخصية في اتخاذ قرارات مستنيرة بشأن مستقبلك، ومواءمة اختياراتك المهنية مع ميولك الطبيعية، وتحقيق نجاح أكبر في حياتك الشخصية والمهنية.',
              },
              careerCards: {
                title: 'تقييم الاختصاص',
                subHeader: 'افتح مسار حياتك المهنية المثالي',
                description:
                  'اختبار المهنة هو تقييم مصمم لمساعدتك على تحديد التخصصات التي تتوافق مع اهتماماتك ونقاط قوتك وشخصيتك. من خلال إجراء اختبار المهنة، تكتسب رؤى قيمة حول المجالات أو الأدوار التي تناسب صفاتك الفريدة بشكل أفضل، مما يزيد من فرصك في العثور على مسار وظيفي مُرضٍ وناجح. إن فهم المهن التي تتوافق مع قدراتك الطبيعية وشغفك يمكّنك من اتخاذ قرارات أكثر استنارة بشأن رحلتك الأكاديمية والمهنية، مما يؤدي إلى قدر أكبر من الرضا والإنجاز في دراستك وحياتك المهنية.',
              },
              skillsCards: {
                title: 'تقييم المهارات',
                subHeader: 'قيم نقاط قوتك وحدد مجالات النمو',
                description:
                  'اختبار المهارات هو تقييم يقيم مدى كفاءتك في المجالات الرئيسية ذات الصلة بأهدافك الأكاديمية والمهنية. من خلال تحديد نقاط قوتك ومجالات التحسين، يوفر هذا الاختبار رؤى قيمة يمكن أن تساعدك على تحسين مهاراتك وتركيز جهودك التعليمية والاستعداد بشكل أفضل لمسيرتك المهنية. تقدم النتائج، المقدمة كنسبة مئوية، فهمًا واضحًا لمستوى مهاراتك الحالي، مما يساعدك على تحديد أهداف مستهدفة للتطوير الشخصي والمهني.',
              },
            },
            buttons: {
              back: 'العودة',
              more: 'عرض المزيد',
              start: 'ابدأ الآن',
              next: 'التالي',
              submit: 'إرسال إجاباتك',
            },
            personalityPage: {
              title: 'تقييم الشخصية',
              subtitle:
                'اكتشف أسرار شخصيتك الفريدة! انغمس في هذه الرحلة المثيرة لاكتشاف الذات وكشف السمات التي تحدد هويتك. استعد لاستكشاف أعماق شخصيتك وإطلاق العنان لإمكاناتك الحقيقية!',
              content:
                'خذ وقتك في الإجابة على كل سؤال — فقد يؤثر ذلك على مستقبلك. كن صبورًا ومتفكرًا أثناء اكتشافك لطريقك نحو النجاح.',
            },
            careerPage: {
              title: 'تقييم الاختصاص',
              subtitle:
                'انطلق في رحلة مثيرة لاكتشاف مسيرتك المهنية المثالية! سيأخذك اختبار المهنة لدينا عبر سلسلة من الأسئلة المصممة لمطابقتك مع أكثر من 140 تخصصًا. اكتشف المجالات التي تتناسب مع شغفك ومهاراتك، وافتح أمامك آفاقًا واسعة لمستقبلك. استعد للعثور على مهنة أحلامك!',
              content:
                'خذ وقتك في الإجابة على كل سؤال — فقد يؤثر ذلك على مستقبلك. كن صبورًا ومتفكرًا أثناء اكتشافك لطريقك نحو النجاح.',
              industry: {
                header: 'استكشف اهتماماتك المهنية',
                description:
                  'في هذه الخطوة الأولى من اختبار المهنة لدينا، ستقدم رؤى حول تفضيلاتك واهتماماتك. ستجيب على أسئلة حول الصناعة المفضلة لديك، نوع مكان العمل الذي تتخيله، نوع العمل الذي تستمتع به، والدورات التي تتطلع إلى استكشافها. ستساعدنا إجاباتك على فهم تطلعاتك المهنية وتقديم توصيات تتماشى مع اهتماماتك.',
              },
              subject: {
                header: 'تقييم أدائك الأكاديمي',
                description:
                  'في هذه الخطوة، سنستعرض أدائك الأكاديمي ودرجاتك في المواد المختلفة. هذه المعلومات ستساعدنا في فهم نقاط قوتك الأكاديمية وتحديد المجالات التي تتفوق فيها، مما يساعدك في اختيار المسارات الدراسية المناسبة لك.',
              },
              simulation: {
                header: 'محاكاة اختبار التخصصات المستقبلية',
                description:
                  'في هذه المرحلة النهائية، ستقوم بإجراء اختبار محاكاة شامل يتعلق بالتخصصات الأكاديمية. ستُطرح عليك مجموعة من الأسئلة المفصلة حول التخصصات المختلفة، مما سيساعدنا في تقييم مدى توافقك مع التخصصات التي اخترتها في البداية بناءً على إجاباتك.<br /><br />لكننا لن نكتفي فقط بإخبارك ما إذا كنت مناسبًا للتخصصات التي اخترتها. سنقوم أيضًا بتقديم اقتراحات لتخصصات أخرى قد تكون ملائمة لك بناءً على إجاباتك في هذا الاختبار، حتى إذا لم تكن التخصصات التي اخترتها في البداية هي الأنسب لك. الهدف هو أن نساعدك في استكشاف خيارات جديدة قد تتناسب بشكل أفضل مع اهتماماتك وقدراتك.',
              },
            },
            skillsPage: {
              title: 'تقييم المهارات',
              subtitle: `اكتشف إمكانياتك الكامنة مع اختبار المهارات المميز لدينا! اغمر نفسك في اكتشاف مواهبك الخفية وصقل القدرات التي تميزك. سواء كنت خبيرًا في حل المشكلات، أو مفكرًا إبداعيًا، أو بارعًا في التواصل، سيكشف لك هذا الاختبار عن نقاط قوتك ويظهر لك ما يمكنك تحقيقه بالفعل. استعد لاكتشاف قواك الخارقة!`,
              content: `خذ وقتك في الإجابة على كل سؤال — فقد يؤثر ذلك على مستقبلك. كن صبورًا ومتفكرًا أثناء اكتشافك لطريقك نحو النجاح.`,
            },
            loginPage: {
              signup: 'انشاء حساب',
              signuptext: 'أنشئ حسابًا جديدًا من خلال ملء استمارة التسجيل.',
              login: 'تسجيل الدخول',
              logintext: 'يرجى إدخال بيانات تسجيل الدخول الخاصة بك.',
              or: 'او',
              email: 'البريد الإلكتروني',
              password: 'كلمة المرور',
              confirmPassword: 'تأكيد كلمة المرور',
              username: 'الاسم',
              schoolname: 'اسم المدرسة',
              phonenumber: 'رقم الهاتف',
              dateofbirth: 'تاريخ الميلاد',
              backtologin: 'العودة لتسجيل الدخول',
            },
            popUps: {
              errorMsg: 'حصل خطأ ما. يرجى المحاولة مرة أخرى لاحقًا.',
              downloadpdf: 'تحميل ملف PDF',
              purchasePopUp: {
                // content: ` مع رسالة جاهزة لطلب الوصول إلى الاختبار 'WhatsApp' العملية سريعة وسهلة. سيتم توجيهك إلى`,
                header: 'ليس لديك تصريح',
                content:
                  'سيتم تحويلك إلى واتساب برسالة جاهزة مسبقاً للحصول على تصريح للثلاثة اختبارات مقابل 15$. فقط أرسل الرسالة للبدء!',
                text: 'اشترك الآن',
              },
              incompletePopup: {
                content: 'يرجى الإجابة على جميع الأسئلة لعرض النتائج.',
                button: 'تابع',
              },
              completeTestPopup: {
                content: 'لقد أكملت الاختبار. هل تريد رؤية النتائج؟',
                button: 'عرض النتائج',
              },
              showResultPopup: {
                title: 'تهانينا على إتمام الاختبار',
                description:
                  'بناءً على إجاباتك، قمنا بتقسيم التخصصات المؤهلة إلى مجموعتين. المجموعة الأولى تعتمد على إجاباتك فيما يتعلق بالصناعات وأماكن العمل والدورات الدراسية المرغوبة وأسئلة المحاكاة. المجموعة الثانية تعتمد فقط على أسئلة المحاكاة.',
                content: {
                  subHeader:
                    'تتكون هذه المجموعة من التخصصات الرئيسية المستمدة من إجاباتك في القسم الأول من تقييم المهنة، والذي يتضمن رؤى حول الصناعات وأماكن العمل وأنواع الوظائف والمواد المفضلة لديك وعلامات الفصل النهائي في الثانوية. بالإضافة إلى ذلك، فإنه يدمج إجاباتك مع اختبار تقريب المهن لتوفير تطابق أكثر دقة:',
                  noResultsMessage:
                    'يبدو أنه لا توجد تخصصات بارزة في صناعاتك المفضلة في الوقت الحالي. لا تقلق، فهناك دائماً خيارات أخرى!',
                  alternativeMajors: {
                    subHeader:
                      'تعتمد هذه المجموعة فقط على إجاباتك على أسئلة تقريب المهن. وهذا يعني أن هذه التخصصات لا تتوافق مع الإجابات التي قدمتها في القسم الأول من تقييم المهنة (مثل الصناعات وأماكن العمل ونوع العمل والدورات المفضلة والدرجات):',
                    message:
                      'على الرغم من أن صناعاتك المفضلة لم تسفر عن نتائج بارزة هذه المرة، إلا أن الاختبار أشار إلى تخصصات أخرى واعدة تتناسب مع مهاراتك واهتماماتك.',
                  },
                  additionalMajors: {
                    message:
                      'لم يتم العثور على تخصصات إضافية، لكن هناك العديد من المجالات المثيرة لاستكشافها!',
                  },
                  encouragementMessage:
                    'تذكر، أن هذا الاختبار هو مجرد نقطة انطلاق. استكشف مجالات أخرى حيث تتألق مهاراتك واكتشف فرصًا جديدة!',
                },
                button: 'شارك معنا',
                skillstitle:
                  '<strong> تهانينا على إكمال اختبار المهارات!</strong>',
                skillsDisplaySentence:
                  'استنادًا إلى إجاباتك، قمنا بتجميع نتائج تقييم مهاراتك. سيقدم لك هذا التقرير رؤى حول نقاط قوتك والمجالات التي تحتاج إلى تحسين. ستجد أيضًا المهارات التي تصفك بشكل أفضل وكيف تتماشى مع المسارات المهنية المحتملة.',
                personalitytitle:
                  '<strong>تهانينا على إكمال اختبار الشخصية!</strong>',
                personalityDisplaySentence:
                  'استنادًا إلى إجاباتك، قمنا بتجميع نتائج تقييم شخصيتك. سيقدم لك هذا التقرير رؤى حول سمات شخصيتك وكيفية توافقها مع المسارات المهنية المحتملة.',
                noResulttitle: '<strong>تهانينا على إكمال اختبار !</strong>',
                noResultSkillsContent:
                  'بناءا على الاسئلة التي تم الجواب عليها, فان علامة المهارات لم تتعدى 50 % , مما يعني أنه يجب عليك تنمية مهاراتك في الإبداع وإدارة الوقت وإدارة الإجهاد وحل المشكلات والذكاء العاطفي والقدرة على التكيف, لما في ذلك من أهمية في مستقبلك الاكاديمي والمهني. قد يتضمن التعامل مع هذه المجالات البحث عن تدريب مستهدف، ودعم مهني، وإجراء تعديلات في البيئة المحاط بها.',
                noResultPersonalityContent:
                  'بناءً على إجاباتك، يشير الحصول على أقل من 50% إلى أن لديك شخصية متوازنة لا تميل بشكل كبير نحو أي سمة معينة، مما يعني أنك قابل للتكيف ومتعدد المواهب وتظهر انفتاح الذهن والمرونة. أنت تحافظ على منظور متوازن، وتقدر مزيجًا من التطبيق العملي والإبداع والتقاليد والتفاعل الاجتماعي دون تفضيل أي مجال على الآخر.',
                skillNote:
                  'إذا كانت أي من مهاراتك حالياً أقل من 50%، فهذا يشير إلى ضرورة تحسين كبير في هذه المجالات الأساسية. تعزيز هذه المهارات أمر حاسم لنجاحك الأكاديمي والمهني. لمعالجة هذه الفجوات، يُنصح بالانخراط في برامج تدريب متخصصة وإجراء تغييرات إيجابية في بيئتك تدعم تطورك الشخصي والمهني.',
              },
              continueDialog: {
                title: 'متابعة',
                content:
                  'لديك تقدم سابق في الاختبار. هل ترغب في استئناف من حيث توقفت أم ترغب في إعادة بدء الاختبار من البداية؟',
                button: 'متابعة',
              },
              warningPopUp: {
                title: 'تحذير',
                content:
                  'سيكون لديك فرصة واحدة فقط للوصول إلى هذا الاختبار. إذا كنت ترغب في إعادة إجراء الاختبار، ستحتاج إلى شرائه مرة أخرى. من فضلك، أجب عن جميع الأسئلة بصبر ودقة لضمان الحصول على أفضل النتائج.',
                agree: 'اوافق',
              },
              ratingPopUp: {
                rating: 'قيم الاختبار',
                feedback: 'ملاحظاتك',
                continue: 'تابع',
                title: 'نود الاطلاع على تجربتك',
                text: '.يرجى تقييم تجربتك في الاختبار أدناه. أخبرنا عن رأيك في الأسئلة وما إذا كانت سهلة. ملاحظاتك تساعدنا على التحسن',
                titleFeedback: '',
                textFeedback:
                  '.ما مدى رضاك عن النتائج التي تلقيتها؟ يرجى تقديم أي تعليقات أو اقتراحات لمساعدتنا على التحسين',
                placeholder: '!شارك ملاحظاتك هنا',
                veryBad: 'سيء جدًا',
                bad: 'سيء',
                notGood: 'أقل من جيد',
                belowAverage: 'أقل من المتوسط',
                average: 'متوسط',
                fair: 'مقبول',
                good: 'جيد',
                veryGood: 'جيد جدًا',
                excellent: 'ممتاز',
                outstanding: 'رائع',
              },
            },
            messages: {
              requestAccess:
                'مرحبًا،\n\nاسمي {{name}}، وبريدي الإلكتروني هو {{email}}. أود طلب الوصول إلى الاختبارات المتاحة على منصتكم. أتطلع إلى استكشاف خياراتي المهنية وأعتقد أن هذه الاختبارات ستكون مهمة في توجيه قراراتي.\n\nشكرًا للنظر في طلبي.\n\nمع أطيب التحيات،\n{{name}}\nالبريد الإلكتروني: {{email}}\nرقم الهاتف: {{studentPhoneNumber}}\nالتاريخ: {{testDate}}',
              feedback:
                'مرحبًا،\n\nأود طلب المساعدة والحصول على مزيد من المعلومات.\n\nشكرًا للنظر في طلبي.\n\nمع أطيب التحيات،\nالتاريخ: {{testDate}}',
              testResults:
                'مرحبًا،\n\nأنا {{name}} ({{email}}). لقد أكملت اختبار التخصصات في {{testDate}} وهذه هي النتائج:\n\nأفضل التخصصات في الصناعات التي أهتم بها:\n{{topSelectedMajors}}\n\nوجدنا أيضًا أنني مناسب لهذه التخصصات الأخرى:\n{{topNonSelectedMajors}}\n\nمع أطيب التحيات،\n{{name}}\nالبريد الإلكتروني: {{email}}\nرقم الهاتف: {{studentPhoneNumber}}\nالتاريخ: {{testDate}}',
            },
            contactPopup: {
              inquiryTitle: 'استفسار حول أداة اكتشاف المسار المهني:',
              placeholderName: 'اسمك',
              placeholderEmail: 'بريدك الإلكتروني',
              placeholderPhone: 'رقم هاتفك',
              placeholderMessage: 'طلبك',
              successMessage: 'تم إرسال طلبك بنجاح!',
              errorMessage: 'حدث خطأ أثناء إرسال طلبك. يرجى المحاولة مرة أخرى.',
              sendButton: 'إرسال',
              closeButton: 'إغلاق',
            },
          },
        },
      },
      fallbackLng: 'en',
      debug: true,
      interpolation: {
        escapeValue: false,
      },
      detection: {
        order: [
          'queryString',
          'cookie',
          'localStorage',
          'sessionStorage',
          'navigator',
          'htmlTag',
          'path',
          'subdomain',
        ],
        caches: ['localStorage', 'cookie'],
      },
    });
}
export default i18n;
