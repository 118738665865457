//#region Imports
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Backdrop, CircularProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';
import MenuBar from '../../Header/MenuBar';
import { Button } from 'primereact/button';
import InfoCard from '../../SharedComponents/InfoCard';
import { useAuth } from '../../../hooks/useAuth';
import PopUp from '../../SharedComponents/PopUps';
import { createWhatsAppUrl } from '../../../utils/utils';
import generatePDF from '../ResultPage';
//#endregion

const TestPage = () => {
  //#region States
  const { handleUpdateAccess, handleGetUserInfo, user, showErrorPopUp } =
    useAuth();
  const [hasAccess, setHasAccess] = useState(false);
  const [purchasePopUp, setPurchasePopUp] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [setHasAccessPopUp] = useState(false);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  //#endregion

  //#region To get the user info constantly to update access and save them in local storage
  useEffect(() => {
    window.scrollTo(0, 0);
    const email = localStorage.getItem('email');
    if (email) {
      setLoading(true);
      handleGetUserInfo(email).finally(() => {
        setLoading(false);
      });
    }
  }, [handleGetUserInfo]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const email = localStorage.getItem('email');
    if (email) {
      setLoading(true);
      handleGetUserInfo(email)
        .then((response) => {
          if (response.success) {
            const {
              accessPersonality,
              accessCareer,
              accessSkills,
              hasAccess,
              topSelectedMajors,
              topNonSelectedMajors,
            } = response.userInfo;
            localStorage.setItem(
              'accessPersonality',
              JSON.stringify(accessPersonality)
            );
            localStorage.setItem('accessCareer', JSON.stringify(accessCareer));
            localStorage.setItem('accessSkills', JSON.stringify(accessSkills));
            localStorage.setItem('hasAccess', JSON.stringify(hasAccess));
            localStorage.setItem(
              'topSelectedMajors',
              JSON.stringify(topSelectedMajors)
            );
            localStorage.setItem(
              'topNonSelectedMajors',
              JSON.stringify(topNonSelectedMajors)
            );

            setHasAccess(hasAccess);
          }
        })
        .catch((error) => {
          console.error('Failed to get user info:', error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [handleGetUserInfo]);

  //#endregion

  //! This for Development Only //
  //#region Handle Update Access
  const handleAccessUpdate = async () => {
    setPurchasePopUp(false);
    setLoading(true);
    await handleUpdateAccess();
    const email = localStorage.getItem('email');
    if (email) {
      handleGetUserInfo(email)
        .then((response) => {
          if (response.success) {
            const { hasAccess } = response.userInfo;
            localStorage.setItem('hasAccess', JSON.stringify(hasAccess));
            setHasAccess(hasAccess);
          }
        })
        .catch((error) => {
          console.error('Failed to get updated user info:', error);
        });
    }
    setLoading(false);
    window.location.reload();
  };
  //#endregion

  //#region Cards Data
  const tests = [
    {
      id: 1,
      imgSrc: 'images/cardHeader1.jpeg',
      title: t('testPage.personalityCards.title'),
      subHeader: t('testPage.personalityCards.subHeader'),
      description: t('testPage.personalityCards.description'),
      shortDesc:
        'Discover your personality type and gain insights into your strengths, challenges, and typical behaviors.',
      handleStartNowClick: () => navigate('/personalityTest'),
      testType: 'Personality',
    },
    {
      id: 2,
      imgSrc: 'images/cardHeader2.jpeg',
      title: t('testPage.careerCards.title'),
      subHeader: t('testPage.careerCards.subHeader'),
      description: t('testPage.careerCards.description'),
      shortDesc: 'Discover your skills and assess your competencies.',
      handleStartNowClick: () => navigate('/careerTest'),
      testType: 'Career',
    },
    {
      id: 3,
      imgSrc: 'images/cardHeader3.jpeg',

      title: t('testPage.skillsCards.title'),
      subHeader: t('testPage.skillsCards.subHeader'),
      shortDesc: 'Discover your skills and assess your competencies.',
      description: t('testPage.skillsCards.description'),
      handleStartNowClick: () => navigate('/skillsTest'),
      testType: 'Skills',
    },
  ];
  //#endregion

  //#region Whatsapp Access Message
  const url = createWhatsAppUrl({
    name: localStorage.getItem('userName'),
    email: localStorage.getItem('email'),
    studentPhoneNumber: localStorage.getItem('phoneNumber'),
    testDate: new Date().toLocaleDateString(),
    topSelectedMajors:
      JSON.parse(localStorage.getItem('topSelectedMajors')) || [],
    topNonSelectedMajors:
      JSON.parse(localStorage.getItem('topNonSelectedMajors')) || [],
    messageTemplate: 'requestAccess',
  });
  //#endregion

  //#region Handle Action Buttons
  const handleSendWhatsappRequest = () => {
    setPurchasePopUp(false);
    setTimeout(() => {
      window.open(url, '_blank');
      navigate('/test', { replace: true });
      //setHasAccessPopUp(true);
    }, 300);
  };

  const handleStartNowClick = () => {
    const authToken = localStorage.getItem('authToken');

    if (authToken == null || authToken === '') {
      navigate('/login', { state: { from: location.pathname } });
    } else {
      setPurchasePopUp(true);
    }
  };
  //#endregion

  //! testing git
  return (
    <>
      <MenuBar />

      <div className="containerHeaderHolder testContainer" id="headerContainer">
        <div className="content">
          <div className="main-header maindemoheader">
            {t('testPage.header.text')}
          </div>
          {/* <div className="sub-header">
            Take the first step towards a successful career today! Unlock Your
            Future and Find the Career That Fits You Best.
          </div> */}
        </div>
        <div className="testButtonHolder">
          <div className="sub-header">{t('testPage.header.subText')}</div>
          <Button
            className="button purchaseButton"
            label={t('testPage.purchasebtn.text')}
            onClick={() => handleStartNowClick()}
          />
          {/* <div>
            for testingg
            < />
          </div> */}
        </div>
      </div>
      <section
        style={{
          margin: '30px auto 70px',
          display: 'flex',
          // flexDirection: "row",
          alignItems: 'center',
          justifyContent: 'center',
          flexWrap: 'wrap',
          gap: '70px',
        }}
      >
        {tests.map((test) => (
          <InfoCard
            key={test.id}
            data={test}
            hasAccess={hasAccess}
            User={user}
            testType={test.testType}
            handleAccessUpdate={handleAccessUpdate}
            whatsappUrl={url}
          />
        ))}
      </section>
      {purchasePopUp && (
        <PopUp
          type="Purchase"
          visible={purchasePopUp}
          content={t('popUps.purchasePopUp.content')}
          // handlePrimaryAction={() => handleAccessUpdate()}
          // ActionLabel={'Api Request'}
          //CancelLabel={t('popUps.purchasePopUp.text')}
          ActionLabel={t('testPage.purchasebtn.text')}
          handlePrimaryAction={() => handleSendWhatsappRequest()}
          //handleSecondaryAction={() => setPurchasePopUp(false)}
          CancelLabel={t('buttons.back')}
          handleSecondaryAction={() => setPurchasePopUp(false)}
        />
      )}
      {/* {hasAccessPopUp && (
        <PopUp
          type="Content"
          visible={hasAccessPopUp}
          content="Access has been successfully requested! We will process your request, and you will be notified once it's completed."
          handlePrimaryAction={() => setHasAccessPopUp(false)}
          ActionLabel={'Continue'}
        />
      )} */}

      {
        <PopUp
          type="Content"
          visible={showErrorPopUp}
          content={t('popUps.errorMsg')}
        />
      }
      <Backdrop open={loading} style={{ zIndex: 1200 }}>
        <CircularProgress size={60} />
      </Backdrop>
    </>
  );
};

export default TestPage;
