// import React, { useEffect } from 'react';
// import { useTranslation } from 'react-i18next';
// import { loadStyles } from '../../utils/utils';

// const LanguageSwitcher = () => {
//   const { i18n } = useTranslation();

//   const changeLanguage = (lng) => {
//     i18n.changeLanguage(lng);
//     localStorage.setItem('i18nextLng', lng);
//     document.documentElement.dir = lng === 'ar' ? 'rtl' : 'ltr';

//     loadStyles(lng);

//     localStorage.removeItem('simulationOption');
//     localStorage.removeItem('personalityQuizState');
//     localStorage.removeItem('quizData');
//     localStorage.removeItem('quizState');
//     localStorage.removeItem('skillsState');
//     localStorage.removeItem('filterMajor');
//     localStorage.removeItem('requiredSubjects');
//     localStorage.removeItem('requiredSubjectsCompleted');
//     localStorage.removeItem('suitableMajors');

//     window.location.reload();
//   };

//   useEffect(() => {
//     const currentLanguage = localStorage.getItem('i18nextLng') || 'en';
//     loadStyles(currentLanguage);
//     document.documentElement.dir = currentLanguage === 'ar' ? 'rtl' : 'ltr';

//     const handleLanguageChange = (language) => {
//       loadStyles(language);
//       document.documentElement.dir = language === 'ar' ? 'rtl' : 'ltr';
//     };

//     i18n.on('languageChanged', handleLanguageChange);

//     return () => {
//       i18n.off('languageChanged', handleLanguageChange);
//     };
//   }, [i18n]);

//   return (
//     <div className="toggle-container">
//       <div className={`toggle-button left`}></div>
//       <div className="toggle-options">
//         <span
//           className={
//             i18n.language === 'en' ||
//             localStorage.getItem('i18nextLng') === 'en-US'
//               ? 'active'
//               : ''
//           }
//           onClick={() => changeLanguage('en')}
//         >
//           English
//         </span>
//         <span
//           className={i18n.language === 'ar' ? 'active' : ''}
//           onClick={() => changeLanguage('ar')}
//         >
//           العربية
//         </span>
//       </div>
//     </div>
//   );
// };

// export default LanguageSwitcher;

import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { loadStyles } from '../../utils/utils';

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem('i18nextLng', lng);
    document.documentElement.dir = lng === 'ar' ? 'rtl' : 'ltr';
    loadStyles(lng);

    // Optionally clear local storage if needed
    localStorage.removeItem('simulationOption');
    localStorage.removeItem('personalityQuizState');
    localStorage.removeItem('quizData');
    localStorage.removeItem('quizState');
    localStorage.removeItem('skillsState');
    localStorage.removeItem('filterMajor');
    localStorage.removeItem('requiredSubjects');
    localStorage.removeItem('requiredSubjectsCompleted');
    localStorage.removeItem('suitableMajors');

    window.location.reload(); // Reload to apply changes
  };

  useEffect(() => {
    const currentLanguage = localStorage.getItem('i18nextLng') || 'en';
    loadStyles(currentLanguage);
    document.documentElement.dir = currentLanguage === 'ar' ? 'rtl' : 'ltr';

    const handleLanguageChange = (language) => {
      loadStyles(language);
      document.documentElement.dir = language === 'ar' ? 'rtl' : 'ltr';
    };

    i18n.on('languageChanged', handleLanguageChange);

    return () => {
      i18n.off('languageChanged', handleLanguageChange);
    };
  }, [i18n]);

  // Determine the displayed language text based on the current language
  const displayedLanguage = i18n.language === 'ar' ? 'English' : 'العربية';

  return (
    <div
      className="language-display"
      onClick={() => changeLanguage(i18n.language === 'ar' ? 'en' : 'ar')}
    >
      {displayedLanguage}
    </div>
  );
};

export default LanguageSwitcher;
