import React from 'react';
import { useInView } from 'react-intersection-observer';
import { useTranslation } from 'react-i18next';
import { coreValuesData } from '../../Data/Data';

const CoreValues = () => {
  const { t } = useTranslation();

  return (
    <div id="coreValueContainer" className="coreValueContainer">
      <img className="coreValueBg" src="images/topCoreValue.svg" alt="bg" />

      <div className="coreValueHolderContainer">
        <div className="content">
          <div
            className="containerHolder"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'row',
                maxWidth: '1000px',
                margin: '0 auto',
                width: '100%',
              }}
            >
              <hr className="line" />
              <span className="mission-text" style={{ fontSize: '27px' }}>
                {t(`coreValues.title`)}{' '}
              </span>
              <hr className="line" />
            </div>
            <div className="container">
              {coreValuesData.map((value, index) => (
                <Card key={index} value={value} t={t} />
              ))}
            </div>
            <div className="coreValueImage">
              <img className="" src="images/CoreBg.png" alt="bg" />
            </div>
          </div>
        </div>
      </div>
      <img className="coreValueBg" src="images/bottomCoreValue.svg" alt="bg" />
    </div>
  );
};

const Card = ({ value, t }) => {
  const [ref, inView] = useInView({ triggerOnce: false, threshold: 0.5 });

  return (
    <div ref={ref} className={`card ${inView ? 'pop' : ''}`}>
      <img className="coreValueIcon" src={value.icon} alt="icon" />
      <div className="title">
        {t(`coreValues.${value.title.toLowerCase()}.title`)}
      </div>
      <div className="description">
        {t(`coreValues.${value.title.toLowerCase()}.description`)}
      </div>
    </div>
  );
};

export default CoreValues;
