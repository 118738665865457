import React, { useEffect, useState } from 'react';
import { Menubar } from 'primereact/menubar';
import { useLocation, useNavigate } from 'react-router-dom';
import LanguageSwitcher from '../SharedComponents/LanguageSwitch';
import { useTranslation } from 'react-i18next';
import { getMenuItems } from '../../Data/Data';

const scrollToSection = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' });
  }
};

const MenuBar = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { t } = useTranslation();

  // State to track authentication
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const authToken = localStorage.getItem('authToken');
    if (authToken !== '' && authToken != null && authToken !== undefined) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
  }, []);

  const withLanguageSwitcher =
    pathname.toLowerCase() === '/' || pathname.toLowerCase() === '/test';

  // Handle logout function
  const handleLogout = () => {
    localStorage.clear();
    setIsAuthenticated(false);
    navigate('/login');
  };

  const menuItems = getMenuItems(
    t,
    navigate,
    scrollToSection,
    LanguageSwitcher,
    withLanguageSwitcher,
    isAuthenticated,
    handleLogout
  );

  const start = <img className="logo" alt="logo" src="images/Logo.png" />;

  return (
    <div className="card">
      <Menubar model={menuItems} start={start} className="custom-menubar" />
    </div>
  );
};

export default MenuBar;
