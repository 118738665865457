export const deatilsMajorsList = {
  Accounting: {
    engName: 'Accounting',
    arName: 'المحاسبة',
    enDescription:
      'Accounting is the systematic process of recording, summarizing, analyzing, and reporting financial transactions of a business or organization.',
    arDescription:
      'المحاسبة هي العملية المنهجية لتسجيل وتلخيص وتحليل والإبلاغ عن المعاملات المالية الخاصة بالشركة.',
  },
  'Accounting and Auditing': {
    engName: 'Accounting and Auditing',
    arName: 'المحاسبة والتدقيق',
    enDescription:
      'Accounting and Auditing is the combination of accounting that involves recording and reporting financial transactions and auditing that involves the independent examination and verification of financial statements and records.',
    arDescription:
      'المحاسبة والتدقيق هو الجمع بين المحاسبة التي تتضمن تسجيل المعاملات المالية والإبلاغ عنها والتدقيق الذي يتضمن الفحص والتحقق المستقلين للبيانات والسجلات المالية.',
  },
  'Accounting and Finance': {
    engName: 'Accounting and Finance',
    arName: 'المحاسبة والتمويل',
    enDescription:
      'Accounting and Finance is the combination of accounting that deals with the recording and reporting of financial transactions and finance that involves the management of assets, liabilities, and the planning of future financial strategies.',
    arDescription:
      'المحاسبة والتمويل هو الجمع بين المحاسبة التي تتعامل مع تسجيل المعاملات المالية والإبلاغ عنها والتمويل الذي ينطوي على إدارة الأصول والخصوم والتخطيط للاستراتيجيات المالية المستقبلية.',
  },
  'Administrative and Politics Sciences': {
    engName: 'Administrative and Politics Sciences',
    arName: 'العلوم الإدارية والسياسية',
    enDescription:
      'Administrative and Politics Sciences studies topics about the creation and implementation of public policies, the roles of various governmental and non-governmental organizations, and the principles of political analysis and leadership.',
    arDescription:
      'العلوم الإدارية والسياسية هي التعرف على كيفية إنشاء السياسات العامة وتنفيذها، وأدوار المنظمات الحكومية وغير الحكومية المختلفة، ومبادئ التحليل السياسي والقيادة.',
  },
  'Advertising and Marketing Communication': {
    engName: 'Advertising and Marketing Communication',
    arName: 'الإعلان والتواصل التسويقي',
    enDescription:
      'Advertising and Marketing Communication studies topics on how to develop compelling messages, create marketing campaigns, and utilize various media channels to reach target audiences effectively.',
    arDescription:
      'الإعلان والتواصل التسويقي هو تعلُم كيفية تطوير رسائل مقنعة وإنشاء حملات تسويقية والاستفادة من قنوات الوسائط المختلفة للوصول إلى الجمهور المستهدف بشكل فعال.',
  },
  'Agri-Business': {
    engName: 'Agri-Business',
    arName: 'الأعمال الزراعية',
    enDescription:
      'Agri-Business focuses on the business aspects of agriculture, including farm management, agricultural finance, marketing, and supply chain logistics.',
    arDescription:
      'الأعمال الزراعية تركُز على الجوانب التجارية للزراعة، بما في ذلك إدارة المزرعة، والتمويل الزراعي، والتسويق، ولوجستيات سلسلة التوريد. ',
  },
  'Agricultural Economics': {
    engName: 'Agricultural Economics',
    arName: 'الاقتصاد الزراعي',
    enDescription:
      'Agricultural Economics focuses on analyzing agricultural production, market trends, and policy impacts, with an emphasis on optimizing resource use, improving farm profitability, and addressing issues related to food supply and rural development.',
    arDescription:
      ' الاقتصاد الزراعي يركُز على تحليل الإنتاج الزراعي، واتجاهات السوق، وتأثيرات السياسات، مع العمل على تحسين استخدام الموارد، وتحسين ربحية المزرعة، ومعالجة القضايا المتعلقة بإمدادات الغذاء والتنمية الريفية. ',
  },
  'Agricultural Engineering': {
    engName: 'Agricultural Engineering',
    arName: 'الهندسة الزراعية',
    enDescription:
      'Agricultural Engineering focuses on designing and optimizing machinery, irrigation systems, and infrastructure to enhance productivity, efficiency, and sustainability in agriculture.',
    arDescription:
      'الهندسة الزراعية تركُز على تصميم وتحسين الآلات وأنظمة الري والبنية التحتية لتعزيز الإنتاجية والكفاءة والاستدامة في الزراعة.',
  },
  'Aircraft Maintenance': {
    engName: 'Aircraft Maintenance',
    arName: 'صيانة الطائرات',
    enDescription:
      'Aircraft Maintenance focuses on the technical skills and knowledge required to inspect, repair, and maintain aircraft to ensure their safety and functionality.',
    arDescription:
      'صيانة الطائرات تركُز على المهارات والمعرفة الفنية المطلوبة لفحص وإصلاح وصيانة الطائرات لضمان سلامتها ووظائفها.',
  },
  'Animal Science and Technology': {
    engName: 'Animal Science and Technology',
    arName: 'علوم وتكنولوجيا الحيوان',
    enDescription:
      'Animal Science and Technology focuses on the study of animal biology, management, and technology to improve the health, productivity, and welfare of domestic animals.',
    arDescription:
      '  علوم وتكنولوجيا الحيوان تركُز على دراسة علم الأحياء الحيوانية وإدارتها وتكنولوجيتها لتحسين صحة الحيوانات الأليفة وإنتاجيتها ورفاهيتها.',
  },
  'Applied Mathematics': {
    engName: 'Applied Mathematics',
    arName: 'الرياضيات التطبيقية',
    enDescription:
      'Applied Mathematics focuses on using mathematical methods and techniques to solve real-world problems across various fields, including engineering, science, economics, and technology.',
    arDescription:
      'الرياضيات التطبيقية تركٌز على استخدام الأساليب والتقنيات الرياضية لحل المشاكل الواقعية في مختلف المجالات، بما في ذلك الهندسة والعلوم والاقتصاد والتكنولوجيا.',
  },
  Archaeology: {
    engName: 'Archaeology',
    arName: 'علم الآثار',
    enDescription:
      'Archaeology focuses on the study of human history through excavation and analysis of artifacts, structures, and other physical remains.',
    arDescription:
      'علم الآثار يركز على دراسة التاريخ البشري من خلال التنقيب وتحليل القطع الأثرية والهياكل والبقايا المادية الأخرى.',
  },
  Architecture: {
    engName: 'Architecture',
    arName: 'الهندسة المعمارية',
    enDescription:
      'Architecture studies topics about architectural design principles, building materials, structural engineering, and environmental considerations.',
    arDescription:
      'الهندسة المعمارية هي التعرف على مبادئ التصميم المعماري ومواد البناء والهندسة الإنشائية والاعتبارات البيئية.',
  },
  'Arts and Interior Planning': {
    engName: 'Arts and Interior Planning',
    arName: 'الفنون والتخطيط الداخلي',
    enDescription:
      'Arts and Interior Planning focuses on space planning, color theory, furniture design, and architectural elements, as well as client needs and trends in interior design.',
    arDescription:
      'الفنون والتخطيط الداخلي تعلٌم المزيد عن تخطيط المساحة، ونظرية الألوان، وتصميم الأثاث، والعناصر المعمارية، بالإضافة إلى احتياجات العملاء والاتجاهات في التصميم الداخلي.',
  },
  Astronomy: {
    engName: 'Astronomy',
    arName: 'علم الفلك',
    enDescription:
      'Astronomy focuses on the study of celestial objects and phenomena, including stars, planets, galaxies, and the universe as a whole.',
    arDescription:
      'علم الفلك يركز على دراسة الأجرام والظواهر السماوية، بما في ذلك النجوم والكواكب والمجرات والكون ككل.',
  },
  'Audiovisual Production': {
    engName: 'Audiovisual Production',
    arName: 'الإنتاج السمعي البصري',
    enDescription:
      'Audiovisual Production studies topics about filming techniques, sound design, editing, and post-production processes, as well as storytelling and project management.',
    arDescription:
      'الإنتاج السمعي البصري يخوُلك التعرف على تقنيات التصوير، وتصميم الصوت، والمونتاج، وعمليات ما بعد الإنتاج، بالإضافة إلى سرد القصص وإدارة المشاريع.',
  },
  'Banking and Finance': {
    engName: 'Banking and Finance',
    arName: 'الخدمات المصرفية والمالية',
    enDescription:
      'Banking and Finance studies topics about financial markets, corporate finance, and regulatory frameworks, preparing them for careers in banking, investment, financial planning, and related fields.',
    arDescription:
      'الخدمات المصرفية والمالية هو التعرف على الأسواق المالية، والتمويل المؤسسي، والأطر التنظيمية، وإعدادهم لمهن في مجال الخدمات المصرفية، والاستثمار، والتخطيط المالي، والمجالات ذات الصلة.',
  },
  Biochemistry: {
    engName: 'Biochemistry',
    arName: 'الكيمياء الحيوية',
    enDescription:
      'Biochemistry studies topics such as enzymology, metabolism, molecular genetics, and biochemical pathways, applying this knowledge to research in medicine, genetics, and biotechnology.',
    arDescription:
      'الكيمياء الحيوية هي دراسة مواضيع مثل علم الإنزيمات، والتمثيل الغذائي، وعلم الوراثة الجزيئي، والمسارات الكيميائية الحيوية، وتطبيق هذه المعرفة في البحث في الطب وعلم الوراثة والتكنولوجيا الحيوية.',
  },
  'Biofood Engineering': {
    engName: 'Biofood Engineering',
    arName: 'هندسة الأغذية الحيوية',
    enDescription:
      'Biofood Engineering studies topics about food technology, bioprocessing, and the design of food production systems, preparing them for careers in the food industry, quality assurance, and food innovation.',
    arDescription:
      'هندسة الأغذية الحيوية  هي التعرف على تكنولوجيا الأغذية والمعالجة الحيوية وتصميم أنظمة إنتاج الأغذية، وإعدادهم لمهن في صناعة الأغذية وضمان الجودة والابتكار في الأغذية.',
  },
  Bioinformatics: {
    engName: 'Bioinformatics',
    arName: 'علم المعلومات الحيوية',
    enDescription:
      'Bioinformatics focuses on using computational tools and techniques to analyze and interpret biological data. It combines biology, computer science, and information technology principles to manage and analyze data.',
    arDescription:
      'علم المعلومات الحيوية يركز على استخدام الأدوات والتقنيات الحاسوبية لتحليل وتفسير البيانات البيولوجية وذلك بالجمع بين مبادئ علم الأحياء وعلوم الكمبيوتر وتكنولوجيا المعلومات.',
  },
  Biology: {
    engName: 'Biology',
    arName: 'علم الأحياء',
    enDescription:
      'Biology studies topics about the structure, function, growth, and development of various life forms, preparing them for careers in research, healthcare, environmental science, and biotechnology.',
    arDescription:
      'علم الأحياء هو التعرف على بنية ووظيفة ونمو وتطور أشكال الحياة المختلفة، وإعدادهم لمهن في مجال البحث والرعاية الصحية والعلوم البيئية والتكنولوجيا الحيوية.',
  },
  'Biomedical Engineering': {
    engName: 'Biomedical Engineering',
    arName: 'هندسة معدات طبية',
    enDescription:
      'Biomedical Engineering involves the development and improvement of medical devices, diagnostic equipment, and biotechnological solutions.',
    arDescription:
      'هندسة معدات طبية يتضمُن تطوير وتحسين الأجهزة الطبية ومعدات التشخيص والحلول التكنولوجية الحيوية.',
  },
  'Biomedical Science': {
    engName: 'Biomedical Science',
    arName: 'العلوم الطبية الحيوية',
    enDescription:
      'Biomedical Science Studies topics about the diagnosis, treatment, and prevention of diseases, preparing them for careers in medical research, clinical laboratories, and healthcare settings.',
    arDescription:
      'العلوم الطبية الحيوية هو التعرف على تشخيص الأمراض وعلاجها والوقاية منها، وإعدادهم للعمل في مجال البحث الطبي والمختبرات السريرية ومؤسسات الرعاية الصحية.',
  },
  'Business Administration': {
    engName: 'Business Administration',
    arName: 'إدارة الأعمال',
    enDescription:
      'Business Administration focuses on the overall management and operation of businesses, covering a broad range of topics such as finance, marketing, human resources, and operations.',
    arDescription:
      'إدارة الأعمال تركُز على الإدارة الشاملة وتشغيل الشركات، ويغطي مجموعة واسعة من المواضيع مثل التمويل والتسويق والموارد البشرية والعمليات.',
  },
  'Business Information Systems': {
    engName: 'Business Information Systems',
    arName: 'أنظمة معلومات الأعمال',
    enDescription:
      'Business Information Systems focuses on the use of IT to solve business problems, improve processes, and support decision-making.',
    arDescription:
      'أنظمة معلومات الأعمال تركُز على استخدام تكنولوجيا المعلومات لحل مشاكل الأعمال وتحسين العمليات ودعم عملية اتخاذ القرار.',
  },
  'Chemical Engineering': {
    engName: 'Chemical Engineering',
    arName: 'الهندسة الكيميائية',
    enDescription:
      'Chemical Engineering focuses on the design, optimization, and operation of processes that transform raw materials into valuable products.',
    arDescription:
      'الهندسة الكيميائية تركُز على تصميم وتحسين وتشغيل العمليات التي تحول المواد الخام إلى منتجات ذات قيمة.',
  },
  Chemistry: {
    engName: 'Chemistry',
    arName: 'الكيمياء',
    enDescription:
      'Chemistry studies topics about chemical reactions, molecular interactions, and laboratory techniques, preparing students for careers in research, pharmaceuticals, environmental science, and chemical manufacturing.',
    arDescription:
      'الكيمياء يخولُك التعرُف على التفاعلات الكيميائية، والتفاعلات الجزيئية، والتقنيات المعملية، وإعدادهم لمهن في مجال البحث، والمستحضرات الصيدلانية، والعلوم البيئية، والتصنيع الكيميائي.',
  },
  'Christian Studies': {
    engName: 'Christian Studies',
    arName: 'الدراسات المسيحية',
    enDescription:
      'Christian Studies focuses on interfaith dialogue, comparative theology, and the social and political impacts of this religion.',
    arDescription:
      'الدراسات المسيحية هي التعرف على الحوار بين الأديان، وعلم اللاهوت المقارن، والتأثيرات الاجتماعية والسياسية لهذه الديانات، وإعدادهم لمهن في الأوساط الأكاديمية، والمنظمات بين الأديان، والقيادة المجتمعية.',
  },
  'Cinema Production': {
    engName: 'Cinema Production',
    arName: 'إنتاج سينمائي',
    enDescription:
      'Cinema Production studies topics about technical skills and creative techniques needed to produce films, from pre-production planning to post-production editing.',
    arDescription:
      'إنتاج سينمائي هو تعلم المهارات الفنية والتقنيات الإبداعية اللازمة لإنتاج الأفلام، من التخطيط لما قبل الإنتاج إلى التحرير بعد الإنتاج.',
  },
  'Civil Engineering': {
    engName: 'Civil Engineering',
    arName: 'الهندسة المدنية',
    enDescription:
      'Civil Engineering prepares students for careers in construction, urban planning, transportation, and environmental management, with roles such as civil engineer, project manager, and structural designer.',
    arDescription:
      'الهندسة المدنية تؤهل التخصص الطلاب للعمل في مجالات البناء والتخطيط الحضري والنقل والإدارة البيئية، مع أدوار مثل المهندس المدني ومدير المشروع والمصمم الإنشائي.',
  },
  'Civil Environmental Engineering': {
    engName: 'Civil Environmental Engineering',
    arName: 'الهندسة المدنية والبيئية',
    enDescription:
      'Civil Environmental Engineering combines civil engineering principles with environmental science and focuses on water resources management, waste treatment, pollution control, and sustainable construction practices.',
    arDescription:
      'الهندسة المدنية والبيئية تجمع بين مبادئ الهندسة المدنية والعلوم البيئية وتركُز على مجالات مثل إدارة موارد المياه ومعالجة النفايات ومكافحة التلوث وممارسات البناء المستدامة.',
  },
  'Clinical Laboratory Science': {
    engName: 'Clinical Laboratory Science',
    arName: 'علوم المختبرات السريرية',
    enDescription:
      'Clinical Laboratory Science is a major that focuses on analyzing bodily fluids and tissues to diagnose and monitor diseases.',
    arDescription:
      'علوم المختبرات السريرية هو تخصص يركز على تحليل سوائل الجسم والأنسجة لتشخيص الأمراض ومراقبتها.',
  },
  'Communication and Visual Arts': {
    engName: 'Communication and Visual Arts',
    arName: 'الاتصالات والفنون البصرية',
    enDescription:
      'Communication and Visual Arts focuses on how to create and analyze visual content for various platforms, develop communication strategies, and understand the impact of visual media on society.',
    arDescription:
      'الاتصالات والفنون البصرية هي تعلُم كيفية إنشاء وتحليل المحتوى المرئي لمختلف المنصات، وتطوير استراتيجيات الاتصال، وفهم تأثير الوسائط المرئية على المجتمع.',
  },
  'Communication Engineering': {
    engName: 'Communication Engineering',
    arName: 'هندسة الاتصالات',
    enDescription:
      'Communication Engineering studies the principles of transmitting data and voice over various media, developing technologies for mobile communication, internet infrastructure, and broadcasting.',
    arDescription:
      'هندسة الاتصالات هي التعرف على مبادئ نقل البيانات والصوت عبر الوسائط المختلفة، وتطوير تقنيات الاتصالات المتنقلة، والبنية التحتية للإنترنت، والبث.',
  },
  'Comparative Linguistics Engineering': {
    engName: 'Comparative Linguistics Engineering',
    arName: 'هندسة مقارنة اللغويات',
    enDescription:
      'Comparative Linguistics Engineering focuses on the study and comparison of languages to understand their structures, histories, and relationships.',
    arDescription:
      'هندسة مقارنة اللغويات تركُز على دراسة اللغات ومقارنتها لفهم بنيتها وتاريخها وعلاقاتها.',
  },
  'Computer and Communication Engineering': {
    engName: 'Computer and Communication Engineering',
    arName: 'هندسة الكمبيوتر والاتصالات',
    enDescription:
      'Computer and Communication Engineering covers areas like network design, telecommunications, signal processing, and data transmission alongside core computer engineering topics.',
    arDescription:
      'هندسة الكمبيوتر والاتصالات تغطي مجالات مثل تصميم الشبكات، والاتصالات، ومعالجة الإشارات، ونقل البيانات إلى جانب موضوعات هندسة الكمبيوتر الأساسية.',
  },
  'Computer Engineering': {
    engName: 'Computer Engineering',
    arName: 'هندسة الكمبيوتر',
    enDescription:
      'Computer Engineering focuses on designing, developing, and maintaining computer systems and hardware.',
    arDescription:
      'هندسة الكمبيوتر تركُز على تصميم وتطوير وصيانة أنظمة وأجهزة الكمبيوتر.',
  },
  'Computer Graphics and Interactive Media': {
    engName: 'Computer Graphics and Interactive Media',
    arName: 'الرسومات الحاسوبية والوسائط التفاعلية',
    enDescription:
      'Computer Graphics and Interactive Media focuses on the creation and manipulation of visual content using computer technology, along with the development of interactive digital experiences.',
    arDescription:
      'الرسومات الحاسوبية والوسائط التفاعلية تركُز على إنشاء المحتوى المرئي ومعالجته باستخدام تكنولوجيا الكمبيوتر، إلى جانب تطوير تجارب رقمية تفاعلية.',
  },
  'Computer Network and Data Communications': {
    engName: 'Computer Network and Data Communications',
    arName: 'شبكات الكمبيوتر واتصالات البيانات',
    enDescription:
      'Computer Network and Data Communications is a field that focuses on designing, implementing, and managing interconnected computer systems that exchange data.',
    arDescription:
      'شبكات الكمبيوتر واتصالات البيانات هو مجال يركز على تصميم وتنفيذ وإدارة أنظمة الكمبيوتر المترابطة التي تتبادل البيانات.',
  },
  'Computer Science': {
    engName: 'Computer Science',
    arName: 'علوم الكمبيوتر',
    enDescription:
      'Computer Science Studies topics about programming, data structures, artificial intelligence, databases, and computer networks.',
    arDescription:
      'علوم الكمبيوتر هي التعرُف على البرمجة، وهياكل البيانات، والذكاء الاصطناعي، وقواعد البيانات، وشبكات الكمبيوتر.',
  },
  'Conservation, Restoration Of Cultural Property and Sacred Art': {
    engName: 'Conservation, Restoration Of Cultural Property and Sacred Art',
    arName: 'الحفاظ على الممتلكات الثقافية والفنون المقدسة وترميمها',
    enDescription:
      'Conservation, Restoration of Cultural Property and Sacred Art is a field dedicated to the preservation, protection, and restoration of historical artifacts, artworks, and sacred objects.',
    arDescription:
      'الحفاظ على الممتلكات الثقافية والفنون المقدسة وترميمها هو مجال مخصص للحفاظ وحماية وترميم القطع الأثرية التاريخية والأعمال الفنية والأشياء المقدسة.',
  },
  'Culinary Arts and Restaurant Management': {
    engName: 'Culinary Arts and Restaurant Management',
    arName: 'فنون الطهي وإدارة المطاعم',
    enDescription:
      'Culinary Arts and Restaurant Management is a field that combines the study of cooking techniques and food preparation with the management of restaurant operations.',
    arDescription:
      'فنون الطهي وإدارة المطاعم هو مجال يجمع بين دراسة تقنيات الطبخ وإعداد الطعام وإدارة عمليات المطاعم.',
  },
  'Data Science': {
    engName: 'Data Science',
    arName: 'علم البيانات',
    enDescription:
      'Data Science is a field that focuses on extracting insights and knowledge from data using statistical analysis, machine learning, and computational techniques.',
    arDescription:
      'علم البيانات هو مجال يركز على استخراج الأفكار والمعرفة من البيانات باستخدام التحليل الإحصائي والتعلم الآلي والتقنيات الحسابية.',
  },
  Dentistry: {
    engName: 'Dentistry',
    arName: 'طب الأسنان',
    enDescription:
      'Dentistry is a field focused on the study, diagnosis, prevention, and treatment of diseases and conditions affecting the oral cavity, teeth, gums, and related structures.',
    arDescription:
      'طب الأسنان هو مجال يركٌز على دراسة وتشخيص والوقاية وعلاج الأمراض والحالات التي تؤثر على تجويف الفم والأسنان واللثة والهياكل ذات الصلة.',
  },
  'Design and Applied Arts': {
    engName: 'Design and Applied Arts',
    arName: 'التصميم والفنون التطبيقية',
    enDescription:
      'Design and Applied Arts study topics about design theory, color theory, materials, and digital tools, preparing them for careers in various creative industries where design plays a central role.',
    arDescription:
      'التصميم والفنون التطبيقية هو التعرُف على نظرية التصميم، ونظرية الألوان، والمواد، والأدوات الرقمية، وإعدادهم لمهن في مختلف الصناعات الإبداعية حيث يلعب التصميم دورًا مركزيًا.',
  },
  'Digital Advertising': {
    engName: 'Digital Advertising',
    arName: 'الإعلان الرقمي',
    enDescription:
      'Digital Advertising studies topics about digital marketing strategies, content creation, analytics, and consumer behavior, preparing them for careers in online marketing, digital media, and advertising agencies.',
    arDescription:
      'الإعلان الرقمي هو التعرُف على استراتيجيات التسويق الرقمي وإنشاء المحتوى والتحليلات وسلوك المستهلك، وإعدادهم لمهن في مجال التسويق عبر الإنترنت والوسائط الرقمية ووكالات الإعلان.',
  },
  'Digital Media': {
    engName: 'Digital Media',
    arName: 'الوسائط الرقمية',
    enDescription:
      'Digital Media studies topics about various forms of digital content, including text, images, audio, and video, and how to leverage digital tools and platforms to engage audiences.',
    arDescription:
      'الوسائط الرقمية هي التعرُف على أشكال مختلفة من المحتوى الرقمي، بما في ذلك النصوص والصور والصوت والفيديو، وكيفية الاستفادة من الأدوات والمنصات الرقمية لإشراك الجماهير.',
  },
  'Distribution and Logistics Management': {
    engName: 'Distribution and Logistics Management',
    arName: 'إدارة التوزيع والخدمات اللوجستية',
    enDescription:
      'Distribution and Logistics Management studies topics about optimizing supply chain operations, managing logistics networks, and ensuring timely delivery.',
    arDescription:
      'إدارة التوزيع والخدمات اللوجستية يخولُك التعرف على كيفية تحسين عمليات سلسلة التوريد وإدارة شبكات الخدمات اللوجستية وضمان التسليم في الوقت المناسب.',
  },
  'Early Childhood Education': {
    engName: 'Early Childhood Education',
    arName: 'تعليم الطفولة المبكرة',
    enDescription:
      'Early Childhood Education trains to design and implement curricula, assess developmental milestones, and foster positive early learning experiences.',
    arDescription:
      'تعليم الطفولة المبكرة هو التدريب على تصميم المناهج وتنفيذها، وتقييم المعالم التنموية، وتعزيز تجارب التعلم المبكرة الإيجابية.',
  },
  'Earth and Life Sciences': {
    engName: 'Earth and Life Sciences',
    arName: 'علوم الأرض والحياة',
    enDescription:
      'Earth and Life Science studies topics about Earth structure, climate systems, and the diverse forms of life, including their interactions and adaptations.',
    arDescription:
      'علوم الأرض والحياة هي التعرف على بنية الأرض، وأنظمة المناخ، وأشكال الحياة المتنوعة، بما في ذلك تفاعلاتها وتكيفاتها.',
  },
  Economics: {
    engName: 'Economics',
    arName: 'الاقتصاد',
    enDescription:
      'Economics studies topics about microeconomics, macroeconomics, econometrics, and economic forecasting.',
    arDescription:
      'الاقتصاد هو تعلُم الاقتصاد الجزئي والاقتصاد الكلي والاقتصاد القياسي والتوقعات الاقتصادية.',
  },
  Education: {
    engName: 'Education',
    arName: 'التعليم',
    enDescription:
      'Education focuses on the study of teaching methods, learning processes, and educational systems.',
    arDescription:
      'التعليم يركُز على دراسة أساليب التدريس وعمليات التعلم والأنظمة التعليمية.',
  },
  'Electrical and Electronics Engineering': {
    engName: 'Electrical and Electronics Engineering',
    arName: 'الهندسة الكهربائية والإلكترونية',
    enDescription:
      'Electrical and Electronics Engineering is a combination of electrical and electronic Engineering which mixes power systems and infrastructure with the design and application of electronic devices and circuits.',
    arDescription:
      'الهندسة الكهربائية والإلكترونية هي مزيج من الهندسة الكهربائية والإلكترونية التي تمزج بين أنظمة الطاقة والبنية التحتية مع تصميم وتطبيق الأجهزة والدوائر الإلكترونية.',
  },
  'Electrical Engineering': {
    engName: 'Electrical Engineering',
    arName: 'الهندسة الكهربائية',
    enDescription:
      'Electrical Engineering studies topics about electrical theory, circuit analysis, signal processing, and the application of electrical engineering principles to solve problems in various industries.',
    arDescription:
      'الهندسة الكهربائية هي التعرف على النظرية الكهربائية، وتحليل الدوائر، ومعالجة الإشارات، وتطبيق مبادئ الهندسة الكهربائية لحل المشاكل في مختلف الصناعات.',
  },
  'Electronic Engineering': {
    engName: 'Electronic Engineering',
    arName: 'الهندسة الإلكترونية',
    enDescription:
      'Electronics Engineering focuses on the design and development of electronic circuits and devices. It deals with smaller-scale systems that use electronic components like transistors, diodes, and integrated circuits.',
    arDescription:
      'الهندسة الإلكترونية تركُز على تصميم وتطوير الدوائر والأجهزة الإلكترونية. ويتعامل مع الأنظمة ذات النطاق الأصغر التي تستخدم مكونات إلكترونية مثل الترانزستورات والثنائيات والدوائر المتكاملة.',
  },
  'Engineering Sciences-Food Engineering': {
    engName: 'Engineering Sciences-Food Engineering',
    arName: 'العلوم الهندسية-هندسة الأغذية',
    enDescription:
      'Engineering Sciences-Food Engineering studies topics about food processing technologies, biochemical processes, and the engineering aspects of food production, preparing them for careers in the food industry, quality assurance, and food technology.',
    arDescription:
      'العلوم الهندسية-هندسة الأغذية هي التعرٌف على تكنولوجيات معالجة الأغذية، والعمليات الكيميائية الحيوية، والجوانب الهندسية لإنتاج الأغذية، وإعدادهم لمهن في صناعة الأغذية، وضمان الجودة، وتكنولوجيا الأغذية.',
  },
  'Environmental / Forestry Engineering': {
    engName: 'Environmental / Forestry Engineering',
    arName: 'الهندسة البيئية/هندسة الغابات',
    enDescription:
      'Environmental / Forestry Engineering studies topics about ecosystem management, environmental impact assessment, and the engineering aspects of land and water management.',
    arDescription:
      'الهندسة البيئية/هندسة الغابات هي التعرف على إدارة النظام البيئي، وتقييم الأثر البيئي، والجوانب الهندسية لإدارة الأراضي والمياه.',
  },
  'Environmental Health/ Sciences': {
    engName: 'Environmental Health/ Sciences',
    arName: 'الصحة البيئية/العلوم البيئية',
    enDescription:
      'Environmental Health/ Sciences studies topics about environmental toxins, epidemiology, risk assessment, and environmental regulations.',
    arDescription:
      'الصحة البيئية/العلوم البيئية هي التعرف على السموم البيئية وعلم الأوبئة وتقييم المخاطر واللوائح البيئية.',
  },
  'Ergotherapy/ Occupational Therapy': {
    engName: 'Ergotherapy/ Occupational Therapy',
    arName: 'العلاج المهني/ العلاج الوظيفي',
    enDescription:
      'Ergotherapy/ Occupational Therapy focuses on helping individuals develop, recover, or maintain the skills needed for daily living and work activities.',
    arDescription:
      'العلاج المهني/ العلاج الوظيفي يركُز على مساعدة الأفراد على تطوير أو استعادة أو الحفاظ على المهارات اللازمة للحياة اليومية وأنشطة العمل.',
  },
  'Fashion and Pattern Drafting': {
    engName: 'Fashion and Pattern Drafting',
    arName: 'تصميم الأزياء والأنماط',
    enDescription:
      'Fashion and Pattern Drafting focuses on designing and creating clothing and accessories. Students study how to create and modify patterns, select fabrics, and bring fashion concepts to life.',
    arDescription:
      'تصميم الأزياء والأنماط هو مجال يركٌز على تصميم وتصنيع الملابس والإكسسوارات. يدرس الطلاب كيفية إنشاء وتعديل الأنماط واختيار الأقمشة وتجسيد مفاهيم الموضة.',
  },
  'Food and Science Technology/ Quality Assurance': {
    engName: 'Food and Science Technology/ Quality Assurance',
    arName: 'تكنولوجيا الأغذية والعلوم/ضمان الجودة',
    enDescription:
      'Food and Science Technology/ Quality Assurance is a field that focuses on the science and technology of food production, processing, and safety. Students learn about food preservation, quality control, and regulatory compliance.',
    arDescription:
      'تكنولوجيا الأغذية والعلوم/ضمان الجودة هو مجال يركُز على علم وتكنولوجيا إنتاج الأغذية ومعالجتها وسلامتها. يتعلم الطلاب عن حفظ الأغذية ومراقبة الجودة والامتثال للقواعد التنظيمية.',
  },
  'Forensic Science': {
    engName: 'Forensic Science',
    arName: 'علم الطب الشرعي',
    enDescription:
      'Forensic Science is a field that applies scientific principles and techniques to solve crimes and legal issues. Students learn about forensic biology, chemistry, toxicology, and crime scene investigation.',
    arDescription:
      'علم الطب الشرعي هو مجال يطبق المبادئ والتقنيات العلمية لحل الجرائم والقضايا القانونية. يتعلم الطلاب عن علم الأحياء الجنائي والكيمياء وعلم السموم والتحقيق في مسرح الجريمة.',
  },
  'Fundamental Health Science': {
    engName: 'Fundamental Health Science',
    arName: 'علوم الصحة الأساسية',
    enDescription:
      'Fundamental Health Science studies topics about the mechanisms of health and illness, research methodologies, and foundational concepts that inform clinical practices and health interventions.',
    arDescription:
      'علوم الصحة الأساسية يخولُك التعرف على آليات الصحة والمرض، ومنهجيات البحث، والمفاهيم الأساسية التي تشكل الممارسات السريرية والتدخلات الصحية.',
  },
  Geography: {
    engName: 'Geography',
    arName: 'الجغرافيا',
    enDescription:
      'Geography focuses on studying the Earth landscapes, environments, and the relationships between people and their environments.',
    arDescription:
      'الجغرافيا يركٌز على دراسة المناظر الطبيعية للأرض البيئات والعلاقات بين الناس والبيئة.',
  },
  Geology: {
    engName: 'Geology',
    arName: 'الجيولوجيا',
    enDescription:
      'Geology involves the study of the Earth, which can be beneficial for understanding archaeological sites geological contexts and using techniques like stratigraphy.',
    arDescription:
      'الجيولوجيا يتضمن دراسة الأرض، والتي يمكن أن تكون مفيدة لفهم السياقات الجيولوجية للمواقع الأثرية واستخدام تقنيات مثل الطبقات الأرضية.',
  },
  'Geoscience and Natural Resources': {
    engName: 'Geoscience and Natural Resources',
    arName: 'علوم الأرض والموارد الطبيعية',
    enDescription:
      'Geoscience and Natural Resources is a major that focuses on studying the Earth’s physical structure, processes, and the management of natural resources.',
    arDescription:
      'علوم الأرض والموارد الطبيعية هو تخصص يركز على دراسة البنية الفيزيائية للأرض، والعمليات، وإدارة الموارد الطبيعية.',
  },
  'Graphic Design': {
    engName: 'Graphic Design',
    arName: 'التصميم الجرافيكي',
    enDescription:
      'Graphic Design is a major that focuses on creating visual content to communicate messages and ideas. Students learn to use design software and develop creative problem-solving abilities to produce aesthetically pleasing and effective visual communications.',
    arDescription:
      'التصميم الجرافيكي هو تخصص يركز على إنشاء محتوى مرئي لتوصيل الرسائل والأفكار. يتعلم الطلاب كيفية استخدام برامج التصميم وتطوير قدرات حل المشكلات الإبداعية لإنتاج اتصالات مرئية فعالة وممتعة من الناحية الجمالية.',
  },
  'Health Communication/ Promotion': {
    engName: 'Health Communication/ Promotion',
    arName: 'التواصل الصحي/ الترويج الصحي',
    enDescription:
      'Health Communication/ Promotion studies topics about health education, public health campaigns, media communication, and behavior change theories.',
    arDescription:
      'التواصل الصحي/ الترويج الصحي هو التعرف على التثقيف الصحي، وحملات الصحة العامة، والتواصل الإعلامي، ونظريات تغيير السلوك.',
  },
  History: {
    engName: 'History',
    arName: 'التاريخ',
    enDescription:
      'History provides a broader context of the human past, focusing on different periods, regions, and cultures. Some programs offer a concentration in archaeological studies.',
    arDescription:
      'التاريخ يقدُم سياقًا أوسع للماضي البشري، مع التركيز على فترات ومناطق وثقافات مختلفة. وتقدم بعض البرامج تخصصًا في الدراسات الأثرية.',
  },
  Hospitality: {
    engName: 'Hospitality',
    arName: 'الضيافة',
    enDescription:
      'Hospitality focuses on studying the hospitality industry, including hotel and restaurant management, tourism, event planning, and customer service.',
    arDescription:
      'الضيافة تركُز على دراسة صناعة الضيافة، بما في ذلك إدارة الفنادق والمطاعم، والسياحة، وتخطيط الفعاليات، وخدمة العملاء.',
  },
  'Hotel Management and Tourism': {
    engName: 'Hotel Management and Tourism',
    arName: 'إدارة الفنادق والسياحة',
    enDescription:
      'Hotel Management and Tourism studies topics about hospitality management, tourism planning, guest services, marketing, and financial management within the industry.',
    arDescription:
      'إدارة الفنادق والسياحة تعرُفك على إدارة الضيافة، والتخطيط السياحي، وخدمات الضيوف، والتسويق، والإدارة المالية داخل الصناعة.',
  },
  'Human Resources Management': {
    engName: 'Human Resources Management',
    arName: 'إدارة الموارد البشرية',
    enDescription:
      'Human Resources Management focuses on the study of managing an organizations workforce, including recruitment, training, employee relations, and performance management.',
    arDescription:
      'إدارة الموارد البشرية يركُز على دراسة إدارة القوى العاملة في الشركة بما في ذلك التوظيف والتدريب والعلاقات مع الموظفين وإدارة الأداء.',
  },
  'Industrial Engineering': {
    engName: 'Industrial Engineering',
    arName: 'الهندسة الصناعية',
    enDescription:
      'Industrial Engineering focuses on the study of optimizing complex systems, processes, and organizations to improve efficiency and productivity.',
    arDescription:
      'الهندسة الصناعية تركُز على دراسة تحسين الأنظمة والعمليات والمنظمات المعقدة لتحسين الكفاءة والإنتاجية.',
  },
  'International and Diplomatic Relations': {
    engName: 'International and Diplomatic Relations',
    arName: 'العلاقات الدولية والدبلوماسية',
    enDescription:
      'International and Diplomatic Relations focuses on the study of global politics, diplomacy, international law, and relations between countries.',
    arDescription:
      ' العلاقات الدولية والدبلوماسية تركُز على دراسة السياسة العالمية والدبلوماسية والقانون الدولي والعلاقات بين الدول.',
  },
  'International Business': {
    engName: 'International Business',
    arName: 'الأعمال الدولية',
    enDescription:
      'International Business focuses on the study of global commerce, including the strategies, operations, and challenges of conducting business across international borders.',
    arDescription:
      'الأعمال الدولية تركُز على دراسة التجارة العالمية، بما في ذلك الاستراتيجيات والعمليات والتحديات المتعلقة بإجراء الأعمال التجارية عبر الحدود الدولية.',
  },
  'Islamic Studies': {
    engName: 'Islamic Studies',
    arName: 'الدراسات الإسلامية',
    enDescription:
      'Islamic Studies focuses on the study of Islamic religion, culture, history, and civilization.',
    arDescription:
      'الدراسات الإسلامية تركٌز على دراسة الدين والثقافة والتاريخ والحضارة الإسلامية.',
  },
  'Journalism and Electronic Media': {
    engName: 'Journalism and Electronic Media',
    arName: 'وسائل الإعلام والاتصالات',
    enDescription:
      'Journalism and Electronic Media studies topics about journalism ethics, multimedia storytelling, digital media platforms, broadcasting, and content creation.',
    arDescription:
      'وسائل الإعلام والاتصالات تعرُف على أخلاقيات الصحافة، ورواية القصص المتعددة الوسائط، ومنصات الوسائط الرقمية، والبث، وإنشاء المحتوى.',
  },
  'Laboratory Sciences': {
    engName: 'Laboratory Sciences',
    arName: 'علوم المختبرات',
    enDescription:
      'Laboratory Sciences focuses on the study of scientific techniques and procedures used in laboratory settings to analyze and interpret biological, chemical, and physical samples.',
    arDescription:
      'علوم المختبرات تركُز على دراسة التقنيات والإجراءات العلمية المستخدمة في الإعدادات المختبرية لتحليل وتفسير العينات البيولوجية والكيميائية والفيزيائية.',
  },
  'Landscape Engineering': {
    engName: 'Landscape Engineering',
    arName: 'هندسة المناظر الطبيعية',
    enDescription:
      'Landscape Engineering focuses on the study of designing, planning, and managing outdoor spaces and environments with an emphasis on both aesthetic and functional aspects.',
    arDescription:
      'هندسة المناظر الطبيعية تركُز على دراسة تصميم وتخطيط وإدارة المساحات والبيئات الخارجية مع التركيز على الجوانب الجمالية والوظيفية.',
  },
  Law: {
    engName: 'Law',
    arName: 'الحقوق',
    enDescription:
      'Law studies topics including constitutional, criminal, civil, and international law, as well as legal research, ethics, and court procedures.',
    arDescription:
      'الحقوق هو التعرف على مختلف مجالات القانون، بما في ذلك القانون الدستوري والجنائي والمدني والدولي، فضلاً عن البحث القانوني والأخلاقيات وإجراءات المحكمة.',
  },
  'Mechanical Engineering': {
    engName: 'Mechanical Engineering',
    arName: 'الهندسة الميكانيكية',
    enDescription:
      'Mechanical Engineering focuses on the study of the design, analysis, and manufacturing of mechanical systems and devices.',
    arDescription:
      'الهندسة الميكانيكية تركُز على دراسة تصميم وتحليل وتصنيع الأنظمة والأجهزة الميكانيكية.',
  },
  'Mechatronics Engineering': {
    engName: 'Mechatronics Engineering',
    arName: 'هندسة الميكاترونيك',
    enDescription:
      'Mechatronics Engineering focuses on integrating mechanical, electronic, and computer systems to design and create advanced automated and robotic systems.',
    arDescription:
      'هندسة الميكاترونيك ترٌكز على دمج الأنظمة الميكانيكية والإلكترونية والكمبيوترية لتصميم وإنشاء أنظمة آلية وروبوتية متقدمة.',
  },
  'Media and Communication': {
    engName: 'Media and Communication',
    arName: 'وسائل الإعلام والاتصالات',
    enDescription:
      'Media and Communication focuses on the study of how information and content are created, distributed, and consumed across various media platforms.',
    arDescription:
      'وسائل الإعلام والاتصالات تركُز على دراسة كيفية إنشاء المعلومات والمحتوى وتوزيعها واستهلاكها عبر منصات الوسائط المختلفة.',
  },
  'Medical Imaging Sciences': {
    engName: 'Medical Imaging Sciences',
    arName: 'علوم التصوير الطبي',
    enDescription:
      'Medical Imaging Sciences focuses on the study of techniques and technologies used to capture and interpret images of the human body for diagnostic and therapeutic purposes.',
    arDescription:
      'علوم التصوير الطبي تركُز على دراسة التقنيات والأساليب المستخدمة لالتقاط وتفسير صور جسم الإنسان لأغراض التشخيص والعلاج.',
  },
  'Medical Technology with Concentration in Radiologic Science': {
    engName: 'Medical Technology with Concentration in Radiologic Science',
    arName: 'التكنولوجيا الطبية مع التركيز على العلوم الإشعاعية',
    enDescription:
      'Medical Technology with Concentration in Radiologic Science focuses on the application of medical technology and radiologic techniques to diagnose and treat patients.',
    arDescription:
      'التكنولوجيا الطبية مع التركيز على العلوم الإشعاعية تعمل على تطبيق التكنولوجيا الطبية والتقنيات الإشعاعية لتشخيص وعلاج المرضى.',
  },
  Medicine: {
    engName: 'Medicine',
    arName: 'الطب',
    enDescription:
      'Medicine focuses on the study of the diagnosis, treatment, and prevention of diseases and medical conditions.',
    arDescription:
      'الطب يركز على دراسة تشخيص وعلاج والوقاية من الأمراض والحالات الطبية.',
  },
  Midwifery: {
    engName: 'Midwifery',
    arName: 'قابلة قانونية',
    enDescription:
      'Midwifery focuses on the care of pregnant women, childbirth, and postnatal support for mothers and newborns.',
    arDescription:
      'تخصص القابلة القانونية يركز على رعاية النساء الحوامل والولادة ودعم ما بعد الولادة للأمهات والمواليد الجدد.',
  },
  Music: {
    engName: 'Music',
    arName: 'موسيقى',
    enDescription:
      'Music studies topics about various musical genres, techniques, and instruments, as well as music theory, ear training, and music history.',
    arDescription:
      'موسيقى هي التعرف على مختلف الأنواع الموسيقية والتقنيات والأدوات، بالإضافة إلى النظرية الموسيقية، وتدريب الأذن، وتاريخ الموسيقى.',
  },
  Nursing: {
    engName: 'Nursing',
    arName: 'التمريض',
    enDescription:
      'Nursing focuses on the study of providing medical care and support to patients across various healthcare settings.',
    arDescription:
      'التمريض يركُز على دراسة تقديم الرعاية الطبية والدعم للمرضى في مختلف أماكن الرعاية الصحية.',
  },
  'Nutrition and Dietetics': {
    engName: 'Nutrition and Dietetics',
    arName: 'التغذية وعلم الغذاء',
    enDescription:
      'Nutrition and Dietetics study topics about nutritional science, diet planning, food safety, and the role of diet in preventing and managing diseases.',
    arDescription:
      'التغذية وعلم الغذاء يسمح لك بالتعرُف على علم التغذية، وتخطيط النظام الغذائي، وسلامة الغذاء، ودور النظام الغذائي في الوقاية من الأمراض وإدارتها.',
  },
  'Optics and Optometry': {
    engName: 'Optics and Optometry',
    arName: 'البصريات وقياس البصر',
    enDescription:
      'Optics and Optometry study topics about visual systems, optics, eye diseases, vision correction, and optical instruments.',
    arDescription:
      'البصريات وقياس البصر هي التعرٌف على الأنظمة البصرية، والبصريات، وأمراض العيون، وتصحيح الرؤية، والأجهزة البصرية.',
  },
  'Pet Medicine': {
    engName: 'Pet Medicine',
    arName: 'طب الحيوانات الأليفة',
    enDescription:
      'Pet Medicine focuses on studying veterinary care specifically for companion animals such as dogs, cats, and other pets.',
    arDescription:
      'طب الحيوانات الأليفة يركز على دراسة الرعاية البيطرية خاصة للحيوانات الأليفة مثل الكلاب والقطط والحيوانات الأليفة الأخرى.',
  },
  'Petrochemical Engineering': {
    engName: 'Petrochemical Engineering',
    arName: 'هندسة البتروكيماويات',
    enDescription:
      'Petrochemical Engineering is a specialized branch of chemical engineering that focuses on the production and processing of chemicals derived from petroleum and natural gas.',
    arDescription:
      'هندسة البتروكيماويات هو فرع متخصص من الهندسة الكيميائية يركز على إنتاج ومعالجة المواد الكيميائية المشتقة من البترول والغاز الطبيعي.',
  },
  'Petroleum Engineering': {
    engName: 'Petroleum Engineering',
    arName: 'هندسة البترول',
    enDescription:
      'Petroleum Engineering focuses on the study of the extraction and production of oil and natural gas.',
    arDescription:
      'هندسة البترول يركُز على دراسة استخراج وإنتاج النفط والغاز الطبيعي.',
  },
  Pharmacy: {
    engName: 'Pharmacy',
    arName: 'الصيدلة',
    enDescription:
      'Pharmacy focuses on the study of medications, their effects, and their role in patient care.',
    arDescription:
      'الصيدلة تركُز على دراسة الأدوية وآثارها ودورها في رعاية المرضى.',
  },
  Philosophy: {
    engName: 'Philosophy',
    arName: 'الفلسفة',
    enDescription:
      'Philosophy focuses on the study of fundamental questions about existence, knowledge, ethics, and reason.',
    arDescription:
      'الفلسفة تركُز على دراسة الأسئلة الأساسية حول الوجود والمعرفة والأخلاق والعقل.',
  },
  Photography: {
    engName: 'Photography',
    arName: 'التصوير الفوتوغرافي',
    enDescription:
      'Photography focuses on the study of visual storytelling and the technical aspects of capturing and editing images.',
    arDescription:
      'التصوير الفوتوغرافي يركز على دراسة القصص البصرية والجوانب الفنية لالتقاط الصور وتحريرها.',
  },
  'Physical Education': {
    engName: 'Physical Education',
    arName: 'التعليم الفيزيائي',
    enDescription:
      'Physical Education focuses on teaching and promoting physical activity and health through structured programs in schools or recreational settings.',
    arDescription:
      'التعليم الفيزيائي يركز على تعليم وتعزيز النشاط البدني والصحة من خلال برامج منظمة في المدارس أو الأماكن الترفيهية.',
  },
  'Physical Therapy': {
    engName: 'Physical Therapy',
    arName: 'العلاج الفيزيائي',
    enDescription:
      'Physical Therapy involves the assessment, diagnosis, and treatment of physical impairments and disabilities.',
    arDescription:
      'العلاج الفيزيائي يتضمن تقييم وتشخيص وعلاج الإعاقات والضعف الجسدي.',
  },
  Physics: {
    engName: 'Physics',
    arName: 'الفيزياء',
    enDescription:
      'Physics focuses on studying the fundamental principles governing matter, energy, and the interactions between them.',
    arDescription:
      'الفيزياء تركٌز على دراسة المبادئ الأساسية التي تحكم المادة والطاقة والتفاعلات بينهما.',
  },
  'Plant Production/Protection': {
    engName: 'Plant Production/ protection',
    arName: 'إنتاج النبات/الوقاية',
    enDescription:
      'Plant Production/ Protection focuses on the study of cultivating crops and managing plant health to maximize yield and minimize losses.',
    arDescription:
      'إنتاج النبات/الوقاية يركٌز على دراسة زراعة المحاصيل وإدارة صحة النبات لتحقيق أقصى قدر من الغلة وتقليل الخسائر.',
  },
  'Poultry and Cattle Medicine': {
    engName: 'Poultry and Cattle Medicine',
    arName: 'طب الدواجن والأبقار',
    enDescription:
      'Poultry and Cattle Medicine focuses on the veterinary care of poultry and cattle, including their health management, disease prevention, and treatment.',
    arDescription:
      'طب الدواجن والأبقار يركُز على الرعاية البيطرية للدواجن والماشية، بما في ذلك إدارة صحتهم والوقاية من الأمراض وعلاجها.',
  },
  'Product Design': {
    engName: 'Product Design',
    arName: 'تصميم المنتج',
    enDescription:
      'Product Design studies topics about design principles, materials, manufacturing processes, user experience, and prototyping.',
    arDescription:
      'تصميم المنتج يسمح لك بالتعرُف على مبادئ التصميم والمواد وعمليات التصنيع وتجربة المستخدم والنماذج الأولية.',
  },
  Psychology: {
    engName: 'Psychology',
    arName: 'علم النفس',
    enDescription:
      'Psychology studies topics about various psychological theories, research methods, and practical applications related to cognition, emotion, development, and social interactions.',
    arDescription:
      'علم النفس هو التعرٌف على النظريات النفسية المختلفة، وأساليب البحث، والتطبيقات العملية المتعلقة بالإدراك، والعاطفة، والتطور، والتفاعلات الاجتماعية.',
  },
  Psychomotricity: {
    engName: 'Psychomotricity',
    arName: 'العلاج النفسي الحركي',
    enDescription:
      'Psychomotricity studies topics about the coordination of mental and physical activities, including fine and gross motor skills, sensory integration, and movement control.',
    arDescription:
      'العلاج النفسي الحركي يسمح لك التعرف على تنسيق الأنشطة العقلية والجسدية، بما في ذلك المهارات الحركية الدقيقة والخشنة، والتكامل الحسي، والتحكم في الحركة.',
  },
  'Public Health': {
    engName: 'Public Health',
    arName: 'الصحة العامة',
    enDescription:
      'Public Health studies topics about disease prevention, health promotion, epidemiology, environmental health, and health policy.',
    arDescription:
      'الصحة العامة يسمح لك بالتعرٌف على الوقاية من الأمراض، وتعزيز الصحة، وعلم الأوبئة، والصحة البيئية، والسياسة الصحية.',
  },
  'Quality Control of Foodstuffs of Animal Origin': {
    engName: 'Quality Control of Foodstuffs of Animal Origin',
    arName: 'مراقبة جودة المواد الغذائية ذات الأصل الحيواني',
    enDescription:
      'Quality Control of Foodstuffs of Animal Origin studies topics about food safety regulations, inspection techniques, quality assurance processes, and microbiological testing specific to meat, dairy, and other animal products.',
    arDescription:
      'مراقبة جودة المواد الغذائية ذات الأصل الحيواني تعرٌفك على لوائح سلامة الأغذية، وتقنيات التفتيش، وعمليات ضمان الجودة، والاختبارات الميكروبيولوجية الخاصة باللحوم ومنتجات الألبان وغيرها من المنتجات الحيوانية.',
  },
  'Radio/TV and Film': {
    engName: 'Radio/TV and Film',
    arName: 'الراديو والتلفزيون والسينما',
    enDescription:
      'Radio/TV and Film focuses on the production, direction, and management of audio-visual content across various media platforms.',
    arDescription:
      'الراديو والتلفزيون والسينما تركٌز على إنتاج وتوجيه وإدارة المحتوى السمعي والبصري عبر منصات الوسائط المختلفة.',
  },
  'Religions Sciences': {
    engName: 'Religions Sciences',
    arName: 'علوم الأديان',
    enDescription:
      'Religions Sciences focuses on the study of religious beliefs, practices, and institutions across various cultures and historical contexts.',
    arDescription:
      'علوم الأديان تركٌز على دراسة المعتقدات والممارسات والمؤسسات الدينية عبر مختلف الثقافات والسياقات التاريخية.',
  },
  'Social Sciences': {
    engName: 'Social Sciences',
    arName: 'علوم اجتماعية',
    enDescription:
      'Social Sciences focuses on the study of human society, behavior, and social relationships.',
    arDescription:
      'علوم اجتماعية تركٌز على دراسة المجتمع البشري والسلوك والعلاقات الاجتماعية.',
  },
  'Social Work': {
    engName: 'Social Work',
    arName: 'العمل الاجتماعي',
    enDescription:
      'Social Work focuses on the study of supporting individuals and communities to improve their well-being and address social issues.',
    arDescription:
      'العمل الاجتماعي يركز على دراسة دعم الأفراد والمجتمعات لتحسين رفاهيتهم ومعالجة القضايا الاجتماعية.',
  },
  'Sociology-Anthropology': {
    engName: 'Sociology-Anthropology',
    arName: 'علم الاجتماع والأنثروبولوجيا',
    enDescription:
      'Sociology-Anthropology is the study of humans, and their ancestors, which includes cultural, social, biological, and linguistic aspects.',
    arDescription:
      'علم الاجتماع والأنثروبولوجيا هو دراسة البشر وأسلافهم والتي تشمل الجوانب الثقافية والاجتماعية والبيولوجية واللغوية.',
  },
  'Speech Therapy': {
    engName: 'Speech Therapy',
    arName: 'علاج النطق',
    enDescription:
      'Speech Therapy focuses on the assessment, diagnosis, and treatment of speech, language, and communication disorders.',
    arDescription:
      'علاج النطق يركُز على تقييم وتشخيص وعلاج اضطرابات النطق واللغة والتواصل.',
  },
  Statistics: {
    engName: 'Statistics',
    arName: 'الإحصاء',
    enDescription:
      'Statistics focuses on the study of data collection, analysis, interpretation, and presentation.',
    arDescription:
      'الإحصاء يركُز على دراسة جمع البيانات وتحليلها وتفسيرها وعرضها.',
  },
  'Surveying Engineering': {
    engName: 'Surveying Engineering',
    arName: 'هندسة المساحة',
    enDescription:
      'Surveying Engineering focuses on the measurement, analysis, and management of land and property boundaries.',
    arDescription:
      'هندسة المساحة تركُز على قياس وتحليل وإدارة حدود الأراضي والممتلكات.',
  },
  'Telecommunication and Networks Engineering': {
    engName: 'Telecommunication and Networks Engineering',
    arName: 'هندسة الاتصالات والشبكات',
    enDescription:
      'Telecommunication and Networks Engineering studies topics about telecommunications technologies, network architecture, data transmission, and wireless communications.',
    arDescription:
      'هندسة الاتصالات والشبكات هي التعرُف على تكنولوجيا الاتصالات، وهندسة الشبكات، ونقل البيانات، والاتصالات اللاسلكية.',
  },
  Theology: {
    engName: 'Theology',
    arName: 'علم اللاهوت',
    enDescription:
      'Theology explores theological concepts, sacred texts, religious history, and the philosophy of religion.',
    arDescription:
      'علم اللاهوت هو استكشاف المفاهيم اللاهوتية، والنصوص المقدسة، والتاريخ الديني، وفلسفة الدين.',
  },
  'Translation and Interpretation': {
    engName: 'Translation and Interpretation',
    arName: 'ترجمة',
    enDescription:
      'Translation and Interpretation studies topics about language proficiency, translation techniques, interpretation skills, and cultural nuances.',
    arDescription:
      'الترجمة تسمح لك بالتعرُف على كيفية إتقان اللغة، وتقنيات الترجمة، ومهارات التفسير، والفروق الثقافية.',
  },
};
