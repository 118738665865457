import React, { useEffect } from 'react';
import AnimatedTitleLoader from '../SharedComponents/AnimatedTitleLoader';

const LoaderPage = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <div className="loaderContainer">
      <AnimatedTitleLoader name={'Start✓Right'} />
    </div>
  );
};

export default LoaderPage;
