//#region Load Styles on Language Change
export const loadStyles = (language) => {
  document
    .querySelectorAll('link[rel="stylesheet"], style')
    .forEach((style) => {
      if (
        style.href?.includes('styles-') ||
        style.innerHTML?.includes('styles-')
      ) {
        style.remove();
      }
    });

  switch (language) {
    case 'ar':
      import('../style/styles-ar.scss')
        .then(() => import('../style/styles-responsive-ar.scss'))
        .then(() => import('../style/styles-animation-ar.scss'))
        .catch((error) => console.error('Error loading Arabic styles:', error));
      break;
    case 'en-US':
    case 'en':
    default:
      import('../style/styles-en.scss')
        .then(() => import('../style/styles-responsive-en.scss'))
        .then(() => import('../style/styles-animation-en.scss'))
        .catch((error) =>
          console.error('Error loading English styles:', error)
        );
      break;
  }
};
//#endregion

//#region Percentage Result threshold
export const percentageResult = (type, correctAnswers) => {
  let result;
  if (
    type === 'Pragmatic Personality' ||
    type === 'Analytical Personality' ||
    type === 'Traditional Personality' ||
    type === 'Artistic Personality' ||
    type === 'Social Personality' ||
    type === 'Innovative Personality' ||
    type === 'شخصية عملية' ||
    type === 'شخصية تحليلية' ||
    type === 'شخصية فنية' ||
    type === 'شخصية اجتماعية' ||
    type === 'شخصية خلاقة' ||
    type === 'شخصية تقليدية'
  ) {
    if (correctAnswers === 4) {
      result = 50;
    } else if (correctAnswers === 5) {
      result = 55;
    } else if (correctAnswers === 6) {
      result = 60;
    } else if (correctAnswers === 7) {
      result = 70;
    } else {
      result = 0;
    }
  } else if (type === 'Creativity' || type === 'الابداع والابتكار') {
    if (correctAnswers === 1) {
      result = 20;
    } else if (correctAnswers === 2) {
      result = 30;
    } else if (correctAnswers === 3) {
      result = 40;
    } else if (correctAnswers === 4) {
      result = 50;
    } else if (correctAnswers === 5) {
      result = 55;
    } else if (correctAnswers === 6) {
      result = 60;
    } else if (correctAnswers === 7) {
      result = 65;
    } else if (correctAnswers === 8) {
      result = 70;
    } else {
      result = 0;
    }
  } else if (type === 'Communication' || type === 'فن التواصل') {
    if (correctAnswers === 1) {
      result = 30;
    } else if (correctAnswers === 2) {
      result = 40;
    } else if (correctAnswers === 3) {
      result = 50;
    } else if (correctAnswers === 4) {
      result = 60;
    } else if (correctAnswers === 5) {
      result = 65;
    } else if (correctAnswers === 6) {
      result = 70;
    } else {
      result = 0;
    }
  } else if (
    type === 'Stress Management' ||
    type === 'Time Management' ||
    type === 'Adaptability' ||
    type === 'Emotional Intelligence' ||
    type === 'Problem Solving' ||
    type === 'إدارة القلق' ||
    type === 'إدارة الوقت' ||
    type === 'القدرة على التأقلم' ||
    type === 'الذكاء العاطفي' ||
    type === 'القدرة على حل المشاكل'
  ) {
    if (correctAnswers === 1) {
      result = 30;
    } else if (correctAnswers === 2) {
      result = 40;
    } else if (correctAnswers === 3) {
      result = 50;
    } else if (correctAnswers === 4) {
      result = 60;
    } else if (correctAnswers === 5) {
      result = 70;
    } else {
      result = 0;
    }
  } else {
    result = 0;
  }

  console.log(`Percentage result for ${type}: ${result}`);
  return result;
};
//#endregion

//#region Count Correct Answers
export const correctAnswers = (data) => {
  const listofCorrectAnswers = data.filter(
    (item) => item.selectedOption === item.correctAnswer
  );
  console.log('Correct answers:', listofCorrectAnswers);
  return listofCorrectAnswers;
};

export const countCorrectAnswers = (data, correctAnswersList) => {
  const subjects = Object.keys(data);
  const correctAnswersCount = {};

  subjects.forEach((subject) => {
    correctAnswersCount[subject] = correctAnswersList.filter((result) =>
      data[subject].some((question) => question.id === result.id)
    ).length;
  });

  // console.log('Correct Answers Count:', correctAnswersCount);
  return correctAnswersCount;
};

export const extractErrorMessages = (errorData) => {
  if (errorData && typeof errorData === 'object') {
    return errorData.message || 'An unexpected error occurred.';
  }
  return 'An unexpected error occurred.';
};
//#endregion

//#region Whatsapp Messages
export const createWhatsAppUrl = ({
  name,
  email,
  studentPhoneNumber,
  testDate,
  topSelectedMajors = [],
  topNonSelectedMajors = [],
  messageTemplate,
}) => {
  const formatList = (list) => list.map((major) => `- ${major}`).join('\n');

  const messages = {
    requestAccess: `
Hello,

My name is ${name}, and my email is ${email}. I would like to request access to the tests available on your platform. I am eager to continue exploring my career options and believe that these tests will be instrumental in guiding my decisions.

Thank you for considering my request.

Best regards,
${name}
Email: ${email}
Phone Number: ${studentPhoneNumber}
Date of Test: ${testDate}
`,
    feedback: `
Hello,

My name is ${name} and my email address is ${email}. 

I would like to request assistance and provide some feedback. 

Thank you for considering my request.

Best regards,
${name}
Email: ${email}
Phone Number: ${studentPhoneNumber}
Date of Test: ${testDate}
`,
    testResults: `
Hello,

This is ${name} (${email}). I have completed the majors test on ${testDate} and these are the results:

Top Majors in My Preferred Industries:
${formatList(topSelectedMajors)}

We have found that I am also a great fit for these other majors:
${formatList(topNonSelectedMajors)}

Best regards,
${name}
Email: ${email}
Phone Number: ${studentPhoneNumber}
Date of Test: ${testDate}
`,
  };

  const message = messages[messageTemplate];

  const encodedMessage = encodeURIComponent(message);
  const phoneNumber = '96181665528';
  const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;

  return whatsappUrl;
};

//#endregion

const CustomHeader = ({ htmlContent, style }) => {
  return (
    <div style={style} className="custom-header">
      <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
    </div>
  );
};

export default CustomHeader;

export const formatDate = (dateString) => {
  const date = new Date(dateString);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}-${month}-${day}`;
};
