import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { Toolbar } from 'primereact/toolbar';
import GetAllUsersInfo from '../../hooks/useAdmin';
import PopUp from './PopUps';
import { useTranslation } from 'react-i18next';
import { Password } from 'primereact/password';
import { Calendar } from 'primereact/calendar';
import { useAuth } from '../../hooks/useAuth';
import { extractErrorMessages, formatDate } from '../../utils/utils';
import { Tag } from 'primereact/tag';

export default function PrimeReactDataTable() {
  const { users, handleDeleteUsers } = GetAllUsersInfo();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const { handleRegister, handleUpdateAccess, error } = useAuth();
  const [listOfEmails, setListOfEmails] = useState([]);

  const [lazyState, setLazyState] = useState({
    first: 0,
    rows: 999999999999999999,
    page: 1,
    sortField: 'userId',
    sortOrder: -1,
    filters: {
      email: { value: '', matchMode: 'contains' },
      username: { value: '', matchMode: 'contains' },
      name: { value: '', matchMode: 'contains' },
      schoolName: { value: '', matchMode: 'contains' },
    },
  });
  let networkTimeout = null;
  const [userData, setUserData] = useState([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [customers, setCustomers] = useState([]);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [deleteCustomerDialog, setDeleteCustomerDialog] = useState(false);
  const [accessCustomerDialog, setAccessCustomerDialog] = useState(false);
  const toast = useRef(null);
  const dt = useRef(null);
  const [searchTerm, setSearchTerm] = useState('');

  const [showAddUserDialog, setShowAddUserDialog] = useState(false);
  const [newUser, setNewUser] = useState({
    name: '',
    email: '',
    phoneNumber: '',
    schoolName: '',
    dateofbirth: '',
  });

  useEffect(() => {
    if (!users) {
      return;
    }
    setUserData(users);
  }, [users]);

  useEffect(() => {
    if (userData.length > 0) {
      loadLazyData();
    }
  }, [lazyState, userData]);

  const loadLazyData = () => {
    setLoading(true);

    if (networkTimeout) {
      clearTimeout(networkTimeout);
    }

    networkTimeout = setTimeout(() => {
      let filteredCustomers = userData;

      // Apply global search filter
      if (globalFilter) {
        filteredCustomers = filteredCustomers.filter((item) =>
          Object.keys(item).some((key) =>
            item[key]
              ?.toString()
              .toLowerCase()
              .includes(globalFilter.toLowerCase())
          )
        );
      }

      // Apply sorting
      if (lazyState.sortField) {
        filteredCustomers = filteredCustomers.sort((a, b) => {
          const aValue = a[lazyState.sortField];
          const bValue = b[lazyState.sortField];
          let result = 0;

          if (aValue < bValue) result = -1;
          if (aValue > bValue) result = 1;

          return lazyState.sortOrder * result;
        });
      }

      // Apply pagination
      const start = lazyState.first;
      const end = start + lazyState.rows;
      const paginatedCustomers = filteredCustomers.slice(start, end);

      setTotalRecords(filteredCustomers.length);
      setCustomers(paginatedCustomers);
      setLoading(false);
    }, Math.random() * 1000 + 250);
  };

  const onPage = (event) => {
    setLazyState((prevState) => ({ ...prevState, ...event }));
  };

  const onSort = (event) => {
    setLazyState((prevState) => ({ ...prevState, ...event }));
  };

  const onSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const onSearchSubmit = () => {
    setGlobalFilter(searchTerm);
    setLazyState((prevState) => ({
      ...prevState,
      first: 0,
    }));
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      onSearchSubmit();
    }
  };

  const onSelectAllChange = (event) => {
    const selectAll = event.checked;

    if (selectAll) {
      setSelectedCustomers(userData);
      setSelectAll(true);
    } else {
      setSelectedCustomers([]);
      setSelectAll(false);
    }
  };

  const openNew = () => {
    setSelectedCustomer(null);
    setShowAddUserDialog(true);
  };

  const onSelectionChange = (e) => {
    setSelectedCustomers(e.value);
    // Extract emails from the selected users
    const selectedEmails = e.value.map((user) => user.email);
    setListOfEmails(selectedEmails);
    setSelectAll(e.value === totalRecords);
  };

  const onDeleteSelected = async () => {
    if (listOfEmails.length > 0) {
      try {
        await handleDeleteUsers(listOfEmails);
        toast.current.show({
          severity: 'success',
          summary: 'Deleted',
          detail: 'Users deleted successfully',
        });
        window.location.reload();
      } catch (err) {
        toast.current.show({
          severity: 'error',
          summary: 'Error',
          detail: 'Failed to delete users',
        });
      }
    } else {
      toast.current.show({
        severity: 'warn',
        summary: 'No Selection',
        detail: 'Please select users to delete',
      });
    }
    setDeleteCustomerDialog(false);
  };

  const accessSelected = async () => {
    if (listOfEmails.length > 0) {
      try {
        await handleUpdateAccess(listOfEmails);
        toast.current.show({
          severity: 'success',
          summary: 'Has Access',
          detail: 'Users provided with access successfully',
        });
        window.location.reload();
      } catch (err) {
        toast.current.show({
          severity: 'error',
          summary: 'Error',
          detail: 'Failed to provide user(s) access',
        });
      }
    } else {
      toast.current.show({
        severity: 'warn',
        summary: 'No Selection',
        detail: 'Please select users to provide access',
      });
    }
    setAccessCustomerDialog(false);
  };

  const confirmDeleteSelected = () => {
    setDeleteCustomerDialog(true);
  };

  const rightToolbarTemplate = () => {
    return (
      <div className="flex flex-wrap gap-2">
        <Button
          label="New"
          icon="pi pi-plus"
          severity="success"
          onClick={openNew}
        />
        <Button
          label="Access"
          icon="pi pi-check"
          severity="info"
          onClick={() => setAccessCustomerDialog(true)}
          disabled={!selectedCustomers.length}
        />
        <Button
          label="Delete"
          icon="pi pi-trash"
          severity="danger"
          onClick={confirmDeleteSelected}
          disabled={!selectedCustomers.length}
        />
        <Button
          label="Export"
          icon="pi pi-upload"
          className="p-button-help"
          onClick={() => dt.current.exportCSV()}
        />
        <Button
          icon="pi pi-refresh"
          rounded
          className="p-button-secondary"
          onClick={() => window.location.reload()}
        />
      </div>
    );
  };

  const leftToolbarTemplate = () => {
    return (
      <div className="flex flex-wrap gap-2 align-items-center justify-content-between">
        <InputText
          type="search"
          onInput={onSearchChange}
          placeholder="Search..."
          onChange={onSearchChange}
          onKeyDown={handleKeyDown}
        />
      </div>
    );
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewUser((prevUser) => ({ ...prevUser, [name]: value }));
  };

  const handleSubmitRegister = async (e) => {
    if (e) {
      e.preventDefault();
    }

    // Validate if passwords match
    if (newUser.password !== newUser.confirmPassword) {
      toast.current.show({
        severity: 'error',
        summary: 'Validation Error',
        detail: 'Passwords do not match.',
        life: 5000, // Duration in milliseconds
      });
      return;
    }

    // Validate if passwords match
    if (newUser.phoneNumber && !/^\d{8,}$/.test(newUser.phoneNumber)) {
      toast.current.show({
        severity: 'error',
        summary: 'Validation Error',
        detail: 'Phone number should only be digits and greater than 8 digits.',
        life: 5000, // Duration in milliseconds
      });
      return; // Exit early if phone number is invalid
    }

    try {
      const result = await handleRegister(newUser);

      if (result.success) {
        toast.current.show({
          severity: 'success',
          summary: 'Registration Successful',
          detail: 'User has been successfully added.',
          life: 5000, // Duration in milliseconds
        });
        setShowAddUserDialog(false);
        window.location.reload();
      } else {
        // Handle validation errors
        if (result.errors) {
          const errorMessages = Object.values(result.errors).flat().join(' ');

          toast.current.show({
            severity: 'error',
            summary: 'Registration Error',
            detail: errorMessages,
            life: 5000, // Duration in milliseconds
          });
        } else {
          const errorMessage = result.message || 'An unexpected error occurred';
          toast.current.show({
            severity: 'error',
            summary: 'Registration Error',
            detail: errorMessage,
            life: 5000,
          });
        }
      }
    } catch (error) {
      console.error('Caught error:', error);
      const errorMessage = error.response?.data?.errors
        ? Object.values(error.response.data.errors).flat().join(' ')
        : error.response?.data?.message ||
          error.message ||
          'An unexpected error occurred';
      toast.current.show({
        severity: 'error',
        summary: 'Registration Error',
        detail: errorMessage,
        life: 5000,
      });
    }
    loadLazyData();
  };

  return (
    <div>
      <Toast ref={toast} />
      <div className="card adminTools">
        <Toolbar
          className="mb-4 adminTools"
          left={leftToolbarTemplate}
          right={rightToolbarTemplate}
        />
        <DataTable
          scrollable
          ref={dt}
          value={customers}
          lazy
          //paginator
          first={lazyState.first}
          rows={lazyState.rows}
          totalRecords={totalRecords}
          onPage={onPage}
          onSort={onSort}
          sortField={lazyState.sortField}
          sortOrder={lazyState.sortOrder}
          loading={loading}
          selection={selectedCustomers}
          onSelectionChange={onSelectionChange}
          selectAll={selectAll}
          onSelectAllChange={onSelectAllChange}
          style={{
            width: '85%',
            margin: 'auto',
            border: '1px solid #e5e7eb',
            borderRadius: '6px',
          }}
          globalFilter={globalFilter}
        >
          <Column
            selectionMode="multiple"
            headerStyle={{ width: '3rem' }}
            frozen
          />
          <Column field="userId" header="ID" sortable frozen />
          <Column field="email" header="Email" frozen />
          <Column
            field="username"
            header="Name"
            frozen
            style={{ borderRight: '1px solid #e5e7eb' }}
          />
          <Column
            field="phoneNumber"
            header="Phone Number"
            style={{ minWidth: '150px' }}
          />
          <Column
            field="schoolName"
            header="School Name"
            style={{ minWidth: '150px' }}
          />
          <Column
            field="dateOfBirth"
            header="Date of Birth"
            body={(rowData) => formatDate(rowData.dateOfBirth)}
            style={{ minWidth: '150px' }}
          />
          <Column
            field="hasAccess"
            header="Has Access"
            style={{ minWidth: '160px' }}
            body={(rowData) =>
              rowData.hasAccess ? (
                <Tag severity="success" value="True" rounded></Tag>
              ) : (
                <Tag severity="secondary" value="False" rounded></Tag>
              )
            }
          />
          <Column
            field="accessPersonality"
            header="Access Personality"
            style={{ minWidth: '200px' }}
            body={(rowData) =>
              rowData.accessPersonality ? (
                <Tag severity="success" value="True" rounded></Tag>
              ) : (
                <Tag severity="secondary" value="False" rounded></Tag>
              )
            }
          />
          <Column
            field="accessCareer"
            header="Access Career"
            style={{ minWidth: '160px' }}
            body={(rowData) =>
              rowData.accessCareer ? (
                <Tag severity="success" value="True" rounded></Tag>
              ) : (
                <Tag severity="secondary" value="False" rounded></Tag>
              )
            }
          />
          <Column
            field="accessSkills"
            header="Access Skills"
            style={{ minWidth: '160px' }}
            body={(rowData) =>
              rowData.accessSkills ? (
                <Tag severity="success" value="True" rounded></Tag>
              ) : (
                <Tag severity="secondary" value="False" rounded></Tag>
              )
            }
          />
          <Column
            field="rating"
            header="Rating"
            style={{ minWidth: '150px' }}
          />
          <Column
            field="feedback"
            header="Feedback"
            style={{ minWidth: '200px' }}
          />
          <Column
            field="topSelectedMajors"
            header="Top Selected Majors"
            body={(rowData) => (
              <div
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  maxWidth: '200px',
                }}
                title={rowData.topSelectedMajors}
              >
                {rowData.topSelectedMajors}
              </div>
            )}
          />
          <Column
            field="topNonSelectedMajors"
            header="Top Non-Selected Majors"
            body={(rowData) => (
              <div
                style={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  maxWidth: '200px',
                }}
                title={rowData.topNonSelectedMajors}
              >
                {rowData.topNonSelectedMajors}
              </div>
            )}
          />
        </DataTable>
        <div
          style={{
            textAlign: 'center',
            marginTop: '5px',
            marginBottom: '50px',
            marginRight: '15px',
            fontSize: '12px',
          }}
        >
          Total Users: {totalRecords}
        </div>
      </div>
      {showAddUserDialog && (
        <PopUp
          type="AddUserPopUp"
          visible={showAddUserDialog}
          content={
            <div className="loginDemoOverlay popUp">
              <div className="formGroup">
                <div className="formGroup">
                  <label htmlFor="email">Email</label>
                  <InputText
                    id="email"
                    name="email"
                    type="text"
                    className="pInputText"
                    required
                    value={newUser.email}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="formGroup">
                  <label htmlFor="signup-username">
                    Username{' '}
                    <span
                      style={{
                        color: 'red',
                        fontSize: '10px',
                        fontWeight: 'normal',
                      }}
                    >
                      (Spaces are not allowed)
                    </span>
                  </label>
                  <InputText
                    id="signup-username"
                    name="name"
                    required
                    type="text"
                    className="pInputText"
                    value={newUser.name}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="formGroup">
                  <label htmlFor="phone">Phone Number</label>
                  <InputText
                    id="phone"
                    name="phoneNumber"
                    required
                    type="text"
                    className="pInputText"
                    value={newUser.phoneNumber}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="formGroup">
                  <label htmlFor="schoolName">School Name</label>
                  <InputText
                    id="schoolName"
                    name="schoolName"
                    type="text"
                    className="pInputText"
                    value={newUser.schoolName}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="formGroup">
                  <label htmlFor="dateofbirth">Date of Birth </label>
                  <Calendar
                    id="dateofbirth"
                    name="dateofbirth"
                    value={newUser.dateofbirth}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="formGroup">
                  <label htmlFor="signup-password">Password </label>
                  <Password
                    id="signup-password"
                    name="password"
                    className="pPassword"
                    required
                    value={newUser.password}
                    onChange={handleInputChange}
                  />
                </div>

                <div className="formGroup">
                  <label htmlFor="confirm-password">Confirm Password </label>
                  <Password
                    id="confirm-password"
                    name="confirmPassword"
                    className="pPassword"
                    required
                    value={newUser.confirmPassword}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div
                className="formActions"
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  flexWrap: 'wrap',
                  justifyContent: 'space-around',
                  width: '100%',
                }}
              >
                <button
                  className="button popupButton"
                  onClick={() => handleSubmitRegister()}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                  type="submit"
                >
                  Add User
                </button>
                <button
                  className="p-button-secondary button popupButton"
                  type="button"
                  style={{ display: 'flex', justifyContent: 'center' }}
                  onClick={() => setShowAddUserDialog(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          }
        />
      )}
      {deleteCustomerDialog && (
        <PopUp
          type="Content"
          visible={deleteCustomerDialog}
          content="Are you sure you want to delete the selected user(s)?"
          ActionLabel="Yes"
          CancelLabel="No"
          handlePrimaryAction={() => onDeleteSelected()}
          handleSecondaryAction={() => setDeleteCustomerDialog(false)}
        />
      )}
      {accessCustomerDialog && (
        <PopUp
          type="Content"
          visible={accessCustomerDialog}
          content="Are you sure you want to provide access for the selected user(s)?"
          ActionLabel="Yes"
          CancelLabel="No"
          handlePrimaryAction={() => accessSelected()}
          handleSecondaryAction={() => setAccessCustomerDialog(false)}
        />
      )}
    </div>
  );
}
