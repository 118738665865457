// Menubar
const scrollToSection = (id) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({ behavior: 'smooth' });
  }
};

// Test

const handleWhatsappClick = () => {
  const url = 'https://wa.me/96181665528';
  window.open(url, '_blank');
};

const handleInstagramClick = () => {
  window.open('https://www.instagram.com/startright.lb/', '_blank');
};

const handleLinkedInClick = () => {
  window.open(
    'https://www.linkedin.com/in/start-right-71a52b2b2?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3BKhP3eBOrTrOAUSegpVz7Vw%3D%3',
    '_blank'
  );
};

const handleFacebookClick = () => {
  window.open(
    'https://www.facebook.com/profile.php?id=61556188589824&mibextid=LQQJ4d',
    '_blank'
  );
};
const handleEmailClick = () => {
  const email = 'ghenwa@Startright-lb.com';
  const subject = 'Inquiry about Career Path Discovery Tool';
  const body = `Dear StartRight Team,
  
  I hope this message finds you well.
  
  I am writing to inquire about the Career Path Discovery Tool mentioned on your website. I am interested in learning more about its features and how it can benefit high school students in choosing their career paths.
  
  Could you please provide further details or arrange for a demo?
  
  Thank you for your assistance.
  
  Best regards,
  [Your Name]
  [Your Position]
  [Your Contact Information]`;

  const mailtoUrl = `mailto:${email}?subject=${encodeURIComponent(
    subject
  )}&body=${encodeURIComponent(body)}`;
  window.open(mailtoUrl);
};

// Menu
export const getMenuItems = (
  t,
  navigate,
  scrollToSection,
  LanguageSwitcher,
  withLanguageSwitcher = false,
  isAuthenticated,
  handleLogout
) => {
  const menuItems = [
    {
      label: t('menu.home'),
      icon: 'pi pi-home',
      command: () => {
        navigate('/');
        setTimeout(() => scrollToSection('headerContainer'), 0);
      },
    },
    {
      label: t('menu.aboutUs'),
      icon: 'pi pi-search',
      items: [
        {
          label: t('menu.missionVision'),
          icon: 'pi pi-bolt',
          command: async () => {
            await navigate('/');
            setTimeout(() => scrollToSection('missionVisionContainer'), 0);
          },
        },
        {
          label: t('menu.coreValues'),
          icon: 'pi pi-server',
          command: async () => {
            await navigate('/');
            setTimeout(() => scrollToSection('coreValueContainer'), 0);
          },
        },
      ],
    },
    {
      label: t('menu.test'),
      icon: 'pi pi-graduation-cap',
      command: () => {
        navigate('/test');
        setTimeout(() => scrollToSection('test'), 0);
      },
    },
    {
      label: t('menu.services'),
      icon: 'pi pi-user',
      items: [
        {
          label: t('menu.contactUs'),
          icon: 'pi pi-comments',
          command: async () => {
            await navigate('/');
            setTimeout(() => scrollToSection('furtherInfo'), 0);
          },
        },
        {
          label: t('menu.socials'),
          icon: 'pi pi-share-alt',
          command: async () => {
            await navigate('/');
            setTimeout(() => scrollToSection('socialHolder'), 0);
          },
        },
      ],
    },
  ];

  if (isAuthenticated) {
    menuItems.push({
      label: t('menu.logout'),
      icon: 'pi pi-sign-out',
      command: handleLogout,
    });
  } else {
    menuItems.push({
      label: t('menu.login'),
      icon: 'pi pi-sign-in',
      command: () => {
        navigate('/login');
      },
    });
  }
  withLanguageSwitcher &&
    menuItems.push({
      label: (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <LanguageSwitcher />
        </div>
      ),
    });

  return menuItems;
};

// Services
export const socialMedia = [
  {
    name: 'Facebook',
    icon: 'pi pi-facebook',
    onClick: handleFacebookClick,
  },
  {
    name: 'Instagram',
    icon: 'pi pi-instagram',
    onClick: handleInstagramClick,
  },
  {
    name: 'Linkedin',
    icon: 'pi pi-linkedin',
    onClick: handleLinkedInClick,
  },
  {
    name: 'WhatsApp',
    icon: 'pi pi-whatsapp',
    onClick: handleWhatsappClick,
  },
];

//Core Values
export const coreValuesData = [
  {
    icon: 'images/integrity.png',
    title: 'Integrity',
    description:
      'Honesty and transparency with our clients, partners, and colleagues. High ethical standards in providing career guidance services.',
  },
  {
    icon: 'images/excellence.png',
    title: 'Excellence',
    description:
      'Continuous improvement in career guidance methodologies and practices. Exceeds the expectations in delivering high-quality services.',
  },
  {
    icon: 'images/accountability.png',
    title: 'Accountability',
    description:
      'Confidence in the outcomes of our career guidance. Learning from mistakes and actively seeking ways to improve.',
  },
  {
    icon: 'images/creativity.png',
    title: 'Innovation',
    description:
      'Stay updated with evolving career landscapes. Encouraging a culture that values new ideas, technologies, and solutions.',
  },
  {
    icon: 'images/reliable.png',
    title: 'Reliable',
    description:
      'Consistent and accurate in delivering information and fulfilling promises. Ensuring the credibility and correctness of information delivered.',
  },
];

// Footer

export const menuData = [
  {
    title: 'Menu',
    items: [
      {
        label: 'Home',
        path: '/home',
        command: async (navigate) => {
          await navigate('/');
          setTimeout(() => scrollToSection('headerContainer'), 0);
        },
      },
      {
        label: 'Mission & Vision',
        path: '/home',
        command: async (navigate) => {
          await navigate('/');
          setTimeout(() => scrollToSection('missionVisionContainer'), 0);
        },
      },
      {
        label: 'Core Values',
        path: '/home',
        command: async (navigate) => {
          await navigate('/');
          setTimeout(() => scrollToSection('coreValueContainer'), 0);
        },
      },
    ],
  },
  {
    title: 'Test',
    items: [
      {
        label: 'Start✓Right',
        path: '/test',
        command: (navigate) => {
          navigate('/test');
          setTimeout(() => scrollToSection('test'), 0);
        },
      },
    ],
  },
  {
    title: 'Services',
    items: [
      {
        label: 'Contact Us',
        path: '/home',
        command: async (navigate) => {
          await navigate('/');
          setTimeout(() => scrollToSection('furtherInfo'), 0);
        },
      },
      {
        label: 'Socials',
        path: '/home',
        command: async (navigate) => {
          await navigate('/');
          setTimeout(() => scrollToSection('socialHolder'), 0);
        },
      },
    ],
  },
  {
    title: 'Footer Text',
    text: 'Empowering you to discover your perfect career path and achieve your dreams with expert insights and personalized advice.',
  },
  {
    title: 'Copyright',
    text: `© ${new Date().getFullYear()} Start✓Right. All rights reserved.`,
  },
];
