import React, { useState, useEffect } from 'react';
import emailjs from 'emailjs-com';
import { useTranslation } from 'react-i18next';

const RequestPopup = ({ isOpen, onClose }) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    from_name: '',
    email: '',
    phone_number: '',
    message: '',
  });

  const [message, setMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        'service_6mau3tk',
        'template_erri8ae',
        e.target,
        'qWqjWU735UEBI0kIb'
      )
      .then(
        (result) => {
          setMessage(t('contactPopup.successMessage'));
        },
        (error) => {
          setMessage(t('contactPopup.errorMessage'));
        }
      );

    setFormData({
      from_name: '',
      email: '',
      phone_number: '',
      message: '',
    });
  };

  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }

    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div
      className="contactHolder"
      style={{
        position: 'fixed',
        top: '0',
        left: 0,
        height: '100vh',
        width: '100%',
        opacity: '0.995',
        zIndex: 1000,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
      }}
    >
      <div
        className="popupLinks address"
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'space-around',
          width: '70%',
        }}
      >
        <div className="emailContent" style={{ marginLeft: '0', width: '50%' }}>
          <div className="text">{t('contactPopup.inquiryTitle')}</div>
          <div className="email" style={{ paddingTop: '5px' }}>
            ghenwa@Startright-lb.com
          </div>
        </div>
        <div className="inputHolder" style={{ width: '50%' }}>
          <form
            onSubmit={sendEmail}
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '20px',
              width: '100%',
            }}
          >
            <input
              type="text"
              name="from_name"
              placeholder={t('contactPopup.placeholderName')}
              value={formData.from_name}
              onChange={handleChange}
              required
              style={{ padding: '10px', fontSize: '16px' }}
            />
            <input
              type="email"
              name="email"
              placeholder={t('contactPopup.placeholderEmail')}
              value={formData.email}
              onChange={handleChange}
              required
              style={{ padding: '10px', fontSize: '16px' }}
            />
            <input
              type="text"
              name="phone_number"
              placeholder={t('contactPopup.placeholderPhone')}
              value={formData.phone_number}
              onChange={handleChange}
              required
              style={{ padding: '10px', fontSize: '16px' }}
            />
            <textarea
              name="message"
              placeholder={t('contactPopup.placeholderMessage')}
              value={formData.message}
              onChange={handleChange}
              required
              style={{ padding: '10px', fontSize: '16px', minHeight: '100px' }}
            ></textarea>
            {message && (
              <div
                style={{
                  margin: '0px auto',
                  color: '#263a5b',
                  textAlign: 'center',
                  fontSize: '12px',
                }}
              >
                {message}
              </div>
            )}
            <div style={{ display: 'flex', flexDirection: 'row', gap: '1vw' }}>
              <button
                type="submit"
                className="button requestButton"
                style={{
                  textAlign: 'center',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                {t('contactPopup.sendButton')}
              </button>
              <button
                type="button"
                onClick={onClose}
                className="button requestButton"
                style={{
                  textAlign: 'center',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                }}
              >
                {t('contactPopup.closeButton')}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default RequestPopup;
