import { Button } from 'primereact/button';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import RequestPopup from './RequestPopup';

const ContactUs = () => {
  const { t } = useTranslation();

  const [requestpopUp, setRequestpopUp] = useState(false);
  const handleOpenRequest = () => {
    setRequestpopUp(true);
  };

  const handleCloseRequest = () => {
    setRequestpopUp(false);
  };

  return (
    <div className="furtherInfo" id="furtherInfo">
      <h2 className="title">{t(`contactUs.text`)}</h2>
      <Button className="button" onClick={handleOpenRequest}>
        {t('menu.contactUs')}
      </Button>
      <RequestPopup isOpen={requestpopUp} onClose={handleCloseRequest} />
    </div>
  );
};

export default ContactUs;
